import { Fragment, useMemo } from 'react';
import { useSuspenseQuery } from '@apollo/client';
import { capitalize } from 'lodash';

import { JourneyLayout } from 'src/components/Journey';
import { withSuspense } from 'src/hoc';
import { SuggestedChangesDocument, SuggestedChangeChangeTypeEnum, EscrowPartyTypeEnum } from 'src/graphql/schema';
import { useEscrow } from 'src/context/escrow';
import { EscrowRepresentativesContainer } from 'src/containers/Escrow/Representatives';
import { SuggestChangesSingle } from 'src/components/SuggestChanges';
import { ModalConfirmation } from 'src/components/Misc';
import { confirmMessageOptionsDict } from 'src/shared/entities';

import type { IRepresentative } from 'src/containers/Escrow/Representatives/model/types';
import type { IContact, ISuggestedChangesQuery } from 'src/graphql/schema';

import { ViewFooter, OrganizationView, ActivationNotificationView } from './ui';

type Props = {
  headerTextBlock: React.ReactNode;
  partyType: EscrowPartyTypeEnum;
};

const changeTypeMap = {
  [EscrowPartyTypeEnum.Depositor]: SuggestedChangeChangeTypeEnum.DepositorSuggestedChange,
  [EscrowPartyTypeEnum.Beneficiary]: SuggestedChangeChangeTypeEnum.BeneficiarySuggestedChange,
};

const PartySuggestedChangesView = ({ partyType, headerTextBlock }: Props) => {
  const changeType = changeTypeMap[partyType];
  const {
    escrow,
    escrow: { id: escrowId, currentCompanyRole, agreement },
    ui,
    setConfirmModalOpen,
  } = useEscrow();
  const { data } = useSuspenseQuery<ISuggestedChangesQuery>(SuggestedChangesDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      escrowId,
      changeType,
    },
  });

  const party = escrow[partyType];
  const suggestedChanges = data.suggestedChanges.nodes?.[0] || null;
  const suggestedNewContacts =
    suggestedChanges?.payload?.new_contacts?.map((contact: IContact, index: number) =>
      getRepresentativeFromContact({ ...contact, id: 'new-' + index }, 'add'),
    ) || [];
  const suggestedPartyLabel =
    suggestedChanges?.creatorType === currentCompanyRole ? 'You' : capitalize(suggestedChanges?.creatorType);
  const suggestedEditedContacts = (suggestedChanges?.payload?.edited_contacts || []) as IContact[];
  const suggestedRemovedContacts = (suggestedChanges?.payload?.deleted_contacts || []) as IContact[];
  const totalSuggestedContacts =
    suggestedEditedContacts.length + suggestedRemovedContacts.length + suggestedNewContacts.length;
  const suggestedActivationNotification =
    suggestedChanges && 'activation_notification' in suggestedChanges.payload
      ? String(suggestedChanges.payload['activation_notification'])
      : null;
  const currentRepresentatives = useMemo(() => {
    const editedMap = new Map(suggestedEditedContacts.map((contact) => [contact.id, contact]));
    const removedMap = new Set(suggestedRemovedContacts.map(({ id }) => id));

    return (
      party?.contacts.map((contact) => {
        if (editedMap.has(contact.id)) return getRepresentativeFromContact(editedMap.get(contact.id)!, 'edit');

        if (removedMap.has(contact.id)) return getRepresentativeFromContact(contact, 'remove');

        return getRepresentativeFromContact(contact);
      }) || []
    );
  }, [suggestedEditedContacts, suggestedRemovedContacts, party?.contacts]);

  const representatives = [...currentRepresentatives, ...suggestedNewContacts];

  return (
    <Fragment>
      <JourneyLayout.Main withPadding>
        {headerTextBlock}
        <OrganizationView suggestedChanges={suggestedChanges} partyType={partyType} />
        <EscrowRepresentativesContainer
          role={partyType}
          agreementType={agreement?.agreementForm || ''}
          representatives={representatives}
          changesRequested
          suggestedChangesNode={
            Boolean(totalSuggestedContacts) && (
              <SuggestChangesSingle status={suggestedChanges.partyStatus!} className="mb-4 max-w-880">
                {suggestedPartyLabel} suggested to edit <strong>{totalSuggestedContacts} representatives</strong> for
                this escrow.
              </SuggestChangesSingle>
            )
          }
        />
        <ActivationNotificationView
          partyType={partyType}
          suggestedChangesNode={
            suggestedActivationNotification && (
              <SuggestChangesSingle status={suggestedChanges.partyStatus!} className="mb-4 max-w-880">
                {suggestedPartyLabel} suggested to{' '}
                <strong>{confirmMessageOptionsDict[suggestedActivationNotification]} </strong>
                notifications to the beneficiary contact person.
              </SuggestChangesSingle>
            )
          }
        />
      </JourneyLayout.Main>

      {suggestedChanges?.policy.review && (
        <ViewFooter suggestedChangeId={suggestedChanges.id} escrowId={escrowId} changeType={changeType} />
      )}

      <ModalConfirmation
        isOpen={ui.isConfirmModalOpen}
        title="Your suggestions have been sent for review!"
        text="Codekeeper will now review your change suggestions. We will notify you of the result as soon as possible."
        onClose={() => setConfirmModalOpen(false)}
      />
    </Fragment>
  );
};

function getRepresentativeFromContact(
  { id, email, name, signatory, phone }: IContact,
  suggestType?: 'add' | 'remove' | 'edit',
): IRepresentative {
  return {
    id,
    email,
    name,
    phone: phone || '',
    signatory,
    suggestType,
  };
}

export default withSuspense(PartySuggestedChangesView);
