import { forwardRef, Fragment } from 'react';
import { Form, Input, Row, Col } from 'antd';

import { SubmitButton } from 'src/components/Button';

import type { FormInstance } from 'antd/lib';

import styles from './EscrowPartyForms.module.scss';

export interface IPartyInvitationForm {
  name: string;
  email: string;
  phone?: string;
}

export type Props = {
  onSubmit: (data: IPartyInvitationForm) => Promise<void>;
  onCancel: () => void;
  initialValues?: IPartyInvitationForm;
  withSubmitButton?: boolean;
  isLoading?: boolean;
};

type Ref = FormInstance;

const PartyInvitationForm = forwardRef<Ref, Props>(
  ({ onSubmit, initialValues, withSubmitButton = true, isLoading = false }, ref) => {
    const [form] = Form.useForm<IPartyInvitationForm>();

    return (
      <Form
        ref={ref}
        form={form}
        layout="vertical"
        className="ck-escrow-party__form"
        onFinish={onSubmit}
        aria-label="party-invitation-form"
        name="validateOnly"
        initialValues={initialValues}
      >
        <Row className={styles['row-md']}>
          <Col span={12} className={styles['col-md']}>
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={12} className={styles['col-md']}>
            <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={12} className={styles['col-md']}>
            <Form.Item
              name="phone"
              label={
                <Fragment>
                  Phone Number<sub>optional</sub>
                </Fragment>
              }
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {withSubmitButton && (
          <div className="text-right">
            <SubmitButton
              form={form}
              buttonProps={{
                loading: isLoading,
              }}
            >
              Save details
            </SubmitButton>
          </div>
        )}
      </Form>
    );
  },
);

PartyInvitationForm.displayName = 'PartyInvitationForm';

export default PartyInvitationForm;
