import { Table } from 'antd';
import { Link } from 'react-router-dom';

import { setRequiredEscrowAction } from 'src/utils/helpers';
import { IEscrow, IEscrowsQuery } from 'src/graphql/schema/index.ts';
import { LabelText } from 'src/components/Label';
import { labelStatusTypeEnum as statusTypeEnum, OtherEscrowStatusEnum } from 'src/components/Escrow/escrow.enum';
import { EscrowInvitationActionsContainer } from 'src/containers/Escrows';

type Props = {
  data: IEscrowsQuery;
  currentPage: number;
  setCurrentPage: (currPage: number) => void;
  isLoading?: boolean;
};

export default function ActiveEscrows({ data, currentPage, setCurrentPage, isLoading = false }: Props) {
  const escrowsData = data?.escrows?.nodes.map((item) => {
    // escrow no longer has actionRequired prop so we need to add it to new object instead
    const newItem = { ...item, actionRequired: setRequiredEscrowAction(item as IEscrow) };

    return {
      key: item.id,
      name: `${item.depositor && item.depositor.companyName ? item.depositor.companyName : ''} - ${
        item.beneficiary && item.beneficiary.companyName ? item.beneficiary.companyName : ''
      }`,
      status:
        item.status && item.escrowTermination?.status !== 'accepted' && item.escrowTermination?.status !== 'pending'
          ? item.status
          : OtherEscrowStatusEnum.TerminationRequested,
      actionRequired: newItem.actionRequired,
      action: item,
    };
  });

  const columns = [
    {
      title: () => <strong>ESCROW</strong>,
      dataIndex: 'name',
      width: '40%',
    },

    {
      title: () => <strong>ESCROW STATUS</strong>,
      dataIndex: 'status',
      render: (status: string) => {
        const statusType: (typeof statusTypeEnum)[keyof typeof statusTypeEnum] | undefined =
          status && status in statusTypeEnum ? statusTypeEnum[status as keyof typeof statusTypeEnum] : undefined;

        if (!statusType) return null;

        return <LabelText text={statusType.text} bgColor={statusType.bgColor} />;
      },
      width: '20%',
    },

    {
      title: () => <strong>ACTION REQUIRED</strong>,
      dataIndex: 'actionRequired',
      render: (escrow: string) => <span>{escrow}</span>,
      width: '20%',
    },

    {
      title: () => <strong>ACTION</strong>,
      dataIndex: 'action',
      render: (escrow: IEscrow) => {
        const { escrowInvitation } = escrow;

        if (escrowInvitation?.status === 'pending' && escrowInvitation.policy.accept) {
          return <EscrowInvitationActionsContainer escrowInvitationId={escrowInvitation.id} />;
        } else {
          return (
            <div className="d-flex align-items-center">
              <Link className="table-link" to={`/escrows/${escrow.id}/basics`}>
                View escrow
              </Link>
            </div>
          );
        }
      },
      width: '20%',
    },
  ];

  return (
    <Table
      onChange={(e) => {
        setCurrentPage(e.current!);
      }}
      className="mt-12"
      pagination={{
        current: currentPage,
        pageSize: 10,
        total: (!isLoading && data.escrows.nodesCount) || 0,
        hideOnSinglePage: true,
        showSizeChanger: false,
      }}
      columns={columns}
      dataSource={escrowsData}
      loading={isLoading}
    />
  );
}
