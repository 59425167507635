import classNames from 'classnames';
import { CheckCircleFilled } from '@ant-design/icons';

import styles from './ConfirmationMessageBlock.module.scss';

type Props = {
  value?: boolean;
  className?: string;
};

const ConfirmationMessageBlock = ({ value, className }: Props) => {
  return (
    <div className={classNames(styles['confirm-message'], className)}>
      <div className={styles['selected-option']}>
        <i>
          <CheckCircleFilled />
        </i>
        <strong>{value ? 'Send' : 'Don’t send'}</strong>
      </div>
    </div>
  );
};

export default ConfirmationMessageBlock;
