import { useReducer } from 'react';

import { IAction } from 'src/utils/ts-utilities';

enum ActionEnum {
  EDIT = 'edit',
  SET_INITIAL = 'set-initial',
}

type IActionReducer = IAction<ActionEnum.EDIT, boolean> | IAction<ActionEnum.SET_INITIAL>;

type IState = {
  isEditAccessMode: boolean;
};

const initialState: IState = {
  isEditAccessMode: false,
};

const reducer = (state: IState, action: IActionReducer): IState => {
  switch (action.type) {
    case ActionEnum.EDIT: {
      return {
        isEditAccessMode: action.payload,
      };
    }
    default:
      return state;
  }
};

export const useEscrowMembersSlice = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setEditAccessMode = (isEditAccessMode: boolean) =>
    dispatch({ type: ActionEnum.EDIT, payload: isEditAccessMode });

  const enableEditAccessMode = () => dispatch({ type: ActionEnum.EDIT, payload: true });

  const setInitial = () => dispatch({ type: ActionEnum.SET_INITIAL, payload: {} });

  return {
    ...state,
    setEditAccessMode,
    enableEditAccessMode,
    setInitial,
  };
};
