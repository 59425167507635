import { EscrowMemberRoleEnum } from 'src/graphql/schema';

export const membersOptions: {
  label: string;
  value: string;
}[] = [
  {
    label: 'Administrative',
    value: EscrowMemberRoleEnum.Administrative,
  },
  {
    label: 'Legal',
    value: EscrowMemberRoleEnum.Legal,
  },
  {
    label: 'Viewer',
    value: EscrowMemberRoleEnum.Viewer,
  },
  {
    label: 'Technical',
    value: EscrowMemberRoleEnum.Technical,
  },
  {
    label: 'No access',
    value: 'no-access',
  },
];

export const membersOptionsDict: { [key: string]: string } = membersOptions.reduce(
  (acc, { label, value }) => ({ ...acc, [value]: label }),
  {},
);
