import { capitalize } from 'lodash';

import { useEscrow } from 'src/context/escrow';
import { TextBlock } from 'src/components/Misc';
import { InfoDetailBlock } from 'src/components/InfoDetailBlock';
import { setOrganizationFullAddress } from 'src/containers/Escrow/lib';
import { SuggestChangesList } from 'src/components/SuggestChanges';

import { mapOrganizationSuggestedChanges } from '../lib';

import type { EscrowPartyTypeEnum, ISuggestedChangesQuery } from 'src/graphql/schema';

export type Props = {
  partyType: EscrowPartyTypeEnum;
  suggestedChanges: ISuggestedChangesQuery['suggestedChanges']['nodes'][0];
};

const OrganizationView = ({ suggestedChanges, partyType }: Props) => {
  const {
    escrow,
    escrow: { currentCompanyRole },
  } = useEscrow();

  const party = escrow[partyType];
  const address = {
    street: party?.street || '',
    city: party?.city || '',
    postalCode: party?.postalCode || '',
    region: party?.region || '',
    country: party?.country || '',
  };
  const organizationSuggestedChanges = mapOrganizationSuggestedChanges(suggestedChanges?.payload || {});

  return (
    <div className="border-b border-dashed border-cloud-grey mb-5 pb-5">
      <TextBlock
        as="h4"
        titleColor="dark-blue"
        title={`${capitalize(partyType)} Organization Details`}
        text={`Enter the details of the organization. This includes the registered name and address of the ${partyType}'s entity,\nwhich will be listed in the escrow agreement.`}
        className="mb-4"
      />

      <div className="max-w-880">
        {Boolean(organizationSuggestedChanges.length) && (
          <SuggestChangesList
            status={suggestedChanges.partyStatus as 'accepted' | 'pending'}
            currentCompanyRole={currentCompanyRole!}
            creator={suggestedChanges.creatorType}
            receiver={suggestedChanges.receiverType!}
            items={organizationSuggestedChanges}
            className="mb-4"
          />
        )}
        <InfoDetailBlock
          items={[
            { label: 'Organization Name', value: party?.companyName || '-' },
            { label: 'Website', value: party?.companyWebsite || '-' },
            { label: 'Registration Number', value: party?.companyRegistrationNumber || '-' },
            { label: 'Address', value: setOrganizationFullAddress(address) },
          ]}
        />
      </div>
    </div>
  );
};

export default OrganizationView;
