import { UserInfoContainer } from 'src/containers/Profile';
import { usePageMeta } from 'src/hooks';

export default function Profile() {
  usePageMeta('Profile');

  return (
    <div className="container--535">
      <UserInfoContainer />
    </div>
  );
}
