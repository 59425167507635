const organizationLabelEnum = {
  company_name: 'Organization name',
  company_registration_number: 'Organization reg. number',
  city: 'Organization city',
  country: 'Organization country',
  region: 'Organization region',
  company_website: 'Organization website',
  postal_code: 'Organization postal code',
  street: 'Organization street',
  street_number: 'Organization street number',
};

export const mapOrganizationSuggestedChanges = (payloadChanges: Record<string, string>) =>
  Object.keys(payloadChanges)
    .filter((entity) => entity in organizationLabelEnum)
    .map((entity) => ({
      label: organizationLabelEnum[entity as keyof typeof organizationLabelEnum],
      value: payloadChanges[entity] || 'None',
    }));
