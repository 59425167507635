import { createRequiredContext } from 'src/context/createRequiredContext';
import { EscrowPartyTypeEnum } from 'src/graphql/schema';

import type { IRepresentative, IRepresentativeContextProps } from './types';

import { useRepresentativeSlice } from './representativeSlice';

const [useRepresentative, RepresentativeProvider] = createRequiredContext<
  ReturnType<typeof useRepresentativeSlice> & {
    role: EscrowPartyTypeEnum;
    agreementType: string;
    representatives: IRepresentative[];
    submit?: IRepresentativeContextProps['submit'];
    currentPolicyRole?: IRepresentativeContextProps['currentPolicyRole'];
    changesRequested?: boolean;
    undoRepresentative?: (id: string) => void;
  }
>();

const RepresentativeContextProvider = ({ children, ...rest }: React.PropsWithChildren<IRepresentativeContextProps>) => {
  const representativeDataSlice = useRepresentativeSlice();

  const values = {
    ...representativeDataSlice,
    ...rest,
  };

  return <RepresentativeProvider value={values}>{children}</RepresentativeProvider>;
};

export { useRepresentative, RepresentativeContextProvider };
