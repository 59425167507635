import { Button, Tooltip } from 'antd';
import { Fragment } from 'react';

// TODO: to be replaced with SVG Component
import Delete from 'src/assets/images/icons/delete.svg';
import Edit from 'src/assets/images/icons/edit.svg';

import { useRepresentative } from '../model';

type Props = {
  id: string;
  suggestType?: 'add' | 'edit' | 'remove';
};

const RepresentativeActions = ({ id, suggestType }: Props) => {
  const { editRepresentative, removeRepresentative, undoRepresentative, changesRequested, representatives } =
    useRepresentative();

  const activeSignatories = representatives.filter(({ signatory }) => signatory && suggestType !== 'add');
  const isLastActiveSignatory =
    activeSignatories.length === 1 && activeSignatories.some(({ id: itemId }) => id === itemId);
  const isLastRepresentative =
    representatives.length === 1 || representatives.filter((rep) => rep.suggestType !== 'remove').length === 1;

  const onEdit = () => editRepresentative(id);
  const onRemove = () => removeRepresentative(id);
  const onUndo = () => undoRepresentative?.(id);

  if (suggestType === 'edit' || suggestType === 'remove')
    return (
      <Button
        type="link"
        size="small"
        style={{
          marginLeft: -7,
        }}
        onClick={onUndo}
      >
        Undo
      </Button>
    );

  return (
    <Fragment>
      <Button type="text" onClick={onEdit} className="mr-2 p-0" size="small">
        <img width={22} height={22} src={Edit} alt="edit" />
      </Button>

      {changesRequested && (isLastActiveSignatory || isLastRepresentative) ? (
        <Tooltip title="This is the last active representative or signatory" placement="top">
          <Button type="text" disabled className="p-0" size="small">
            <img width={22} height={22} src={Delete} alt="remove" />
          </Button>
        </Tooltip>
      ) : (
        <Button type="text" onClick={onRemove} className="p-0" size="small">
          <img width={22} height={22} src={Delete} alt="remove" />
        </Button>
      )}
    </Fragment>
  );
};

export default RepresentativeActions;
