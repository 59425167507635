import { gql } from '@apollo/client';

export const EscrowInvitationFields = gql`
  fragment EscrowInvitationFields on EscrowInvitation {
    status
    companyName
    id
    partyType
    user {
      id
      name
      email
      phone
    }
    policy {
      accept
      reject
      update
    }
  }
`;

export const EscrowTerminationFields = gql`
  fragment EscrowTerminationFields on EscrowTermination {
    status
    requestedAt
    acceptedAt
    adminReviewedAt
    requesterParty
    id
    terminateAt
    policy {
      accept
      reject
    }
  }
`;

export const EscrowAgreementFields = gql`
  fragment EscrowAgreementFields on Agreement {
    agreementForm
    id
    jurisdiction
    liability
    approvedByBeneficiary
    approvedByCodekeeper
    approvedByDepositor
    releaseBankruptcy
    releaseCustom
    releaseCustomConditions
    releaseInsolvency
    releaseMaintenance
    thirdPartyAgreement
    updatedAt
    legalContext
    legalContextOther
    depositType
    depositTypeOther
    policy {
      approve
      generate
    }
    approved
  }
`;

export const EscrowPolicyFragment = gql`
  fragment EscrowPolicyFragment on EscrowPolicy {
    activate
    bipartiteUpdate
    createBeneficiary
    deferActivation
    depositAssets
    enableReports
    inviteBeneficiary
    inviteDepositor
    read
    remove
    requestTermination
    update
    write
    createAgreementSuggestedChange
    createBeneficiarySuggestedChange
    createDepositSuggestedChange
    createDepositorSuggestedChange
    createNotificationSuggestedChange
  }
`;

export const DepositorPolicyFragment = gql`
  fragment DepositorPolicyFragment on DepositorPolicy {
    readEscrowMembers
    update
    createEscrowMembers
  }
`;

export const BeneficiaryPolicyFragment = gql`
  fragment BeneficiaryPolicyFragment on BeneficiaryPolicy {
    readEscrowMembers
    update
    createEscrowMembers
  }
`;
