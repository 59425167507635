export const confirmMessageOptions: { label: string; value: boolean }[] = [
  {
    label: 'Send',
    value: true,
  },
  {
    label: 'Don’t send',
    value: false,
  },
];

export const confirmMessageOptionsDict = confirmMessageOptions.reduce<Record<string, string>>((acc, curr) => {
  acc[String(curr.value)] = curr.label;
  return acc;
}, {});
