import { AccountContainer } from 'src/containers/Account';
import { Main } from 'src/components/Layout';
import { usePageMeta } from 'src/hooks';

const AccountPage: React.FunctionComponent = () => {
  usePageMeta('Account');

  return (
    <Main title="Account" className="user-settings">
      <AccountContainer />
    </Main>
  );
};

export default AccountPage;
