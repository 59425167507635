import GraphiQL from 'graphiql';
import { memo } from 'react';

import fetchTokenStrategy from 'src/utils/fetch-token-strategy';
import env from 'env';

const Graphiql = () => (
  <GraphiQL
    fetcher={async (graphQLParams) => {
      const data = await fetch(`${env.VITE_BACKEND_URL}/graphql`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await fetchTokenStrategy()}`,
        },
        body: JSON.stringify(graphQLParams),
        credentials: 'same-origin',
      });
      return data.json().catch(() => data.text());
    }}
  />
);

export default memo(Graphiql);
