import { useReducer } from 'react';

import { IAction } from 'src/utils/ts-utilities';

enum ActionEnum {
  ADD = 'add',
  EDIT = 'edit',
  REMOVE = 'remove',
  SET_INITIAL_DATA = 'set-initial-data',
}

type IActionReducer =
  | IAction<ActionEnum.SET_INITIAL_DATA>
  | IAction<ActionEnum.ADD>
  | IAction<
      ActionEnum.EDIT,
      {
        representativeId: string;
      }
    >
  | IAction<
      ActionEnum.REMOVE,
      {
        representativeId: string;
      }
    >;

export type IState = {
  action: 'add' | 'edit' | 'remove' | null;
  representativeId?: string;
};

const initialState: IState = {
  action: null,
  representativeId: undefined,
};

const reducer = (state: IState, action: IActionReducer): IState => {
  switch (action.type) {
    case ActionEnum.ADD:
      return { ...state, action: 'add' };
    case ActionEnum.EDIT:
      return { ...state, action: 'edit', representativeId: action.payload.representativeId };
    case ActionEnum.REMOVE:
      return { ...state, action: 'remove', representativeId: action.payload.representativeId };
    case ActionEnum.SET_INITIAL_DATA:
      return initialState;
    default:
      return state;
  }
};

export const useRepresentativeSlice = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const addRepresentative = () => dispatch({ type: ActionEnum.ADD, payload: {} });

  const editRepresentative = (representativeId: string) =>
    dispatch({ type: ActionEnum.EDIT, payload: { representativeId } });

  const removeRepresentative = (representativeId: string) =>
    dispatch({ type: ActionEnum.REMOVE, payload: { representativeId } });

  const setInitialData = () => dispatch({ type: ActionEnum.SET_INITIAL_DATA, payload: {} });

  return {
    ...state,
    addRepresentative,
    editRepresentative,
    removeRepresentative,
    setInitialData,
  };
};
