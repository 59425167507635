import { JourneyFooter as EscrowModalFooter } from 'src/components/Journey';
import RequestTerminationActionContainer from 'src/containers/Escrow/Layout/Actions/RequestTerminationActionContainer';
import { ActionSuggestChangesContainer } from 'src/containers/Escrow/SuggestChanges';
import { updateRejectedSuggestedChangesCache } from 'src/graphql/client/cache';
import { SuggestedChangeChangeTypeEnum } from 'src/graphql/schema';

type Props = {
  suggestedChangeId: string;
  escrowId: string;
  changeType: SuggestedChangeChangeTypeEnum;
};

const ViewFooter = ({ suggestedChangeId, escrowId, changeType }: Props) => {
  return (
    <EscrowModalFooter justify="space-between">
      <EscrowModalFooter.Item>
        <RequestTerminationActionContainer />
      </EscrowModalFooter.Item>

      <EscrowModalFooter.Group>
        <EscrowModalFooter.Item>
          <ActionSuggestChangesContainer
            type="reject"
            suggestedChangeId={suggestedChangeId}
            successCallback={() =>
              updateRejectedSuggestedChangesCache(suggestedChangeId, {
                changeType,
                escrowId,
              })
            }
          />
        </EscrowModalFooter.Item>
        <EscrowModalFooter.Item>
          <ActionSuggestChangesContainer type="accept" suggestedChangeId={suggestedChangeId} />
        </EscrowModalFooter.Item>
      </EscrowModalFooter.Group>
    </EscrowModalFooter>
  );
};

export default ViewFooter;
