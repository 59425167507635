import { Fragment } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';

import { CardWithIcon } from 'src/components/Card';
import { TextBlock, ModalConfirmation } from 'src/components/Misc';
import {
  escrowNotificationsDictionary,
  disruptedNotificationsDictionary,
  gracePeriodDictionary,
} from 'src/components/Escrow/Notifications/escrowNotifications.enum';
import { storageRegionDictionary } from 'src/shared/entities';
import { useEscrow } from 'src/context/escrow';
import { useNotificationsSuggestChanges } from 'src/context/suggest-changes';
import { SuggestChangesList } from 'src/components/SuggestChanges';
import { AgreementFormEnum, EscrowPartyTypeEnum, DisruptionNotificationEnum } from 'src/graphql/schema';
import styles from 'src/components/Escrow/Escrow.module.scss';

import { getDisruptedChanges } from '../lib/getDisruptedChanges';

import type { INotificationsChangesPayload } from '../model/types';

const ViewNotificationsChangesContainer = () => {
  const { escrow, ui, setConfirmModalOpen } = useEscrow();
  const { suggestedChanges } = useNotificationsSuggestChanges();

  const beneficiaryDisrupted = getDisruptedConnectionDetails(
    escrow.beneficiaryDisruptionNotification,
    escrow.beneficiaryDisruptionNotificationGracePeriod ?? '',
  );
  const depositorDisrupted = getDisruptedConnectionDetails(
    escrow.depositorDisruptionNotification,
    escrow.depositorDisruptionNotificationGracePeriod ?? '',
  );
  const activeRegionData = storageRegionDictionary[escrow.storageRegion ?? ''];
  const beneficiaryNotificationType = escrowNotificationsDictionary(EscrowPartyTypeEnum.Beneficiary)[
    escrow.beneficiaryDepositNotification
  ];
  const depositorNotificationType = escrowNotificationsDictionary(EscrowPartyTypeEnum.Depositor)[
    escrow.depositorDepositNotification
  ];

  const suggestedChangesPayload: INotificationsChangesPayload = suggestedChanges?.payload;
  const disruptedSuggestedChangesItems = getDisruptedChanges(suggestedChangesPayload);

  return (
    <Fragment>
      {escrow.agreement?.agreementForm === AgreementFormEnum.Tripartite ? (
        <div className={styles.block}>
          <TextBlock
            className={styles['mb--20']}
            title="Beneficiary Deposit Information Display"
            text="This setting determines whether information of the deposited material will be visible in the beneficiary's dashboard."
          />
          {suggestedChanges && typeof suggestedChangesPayload?.['show_deposit_information'] === 'boolean' && (
            <SuggestChangesList
              className={styles['container--990']}
              status={suggestedChanges.status as 'accepted' | 'pending'}
              currentCompanyRole={escrow.currentCompanyRole as 'beneficiary' | 'depositor'}
              creator={suggestedChanges.creator as 'beneficiary' | 'depositor'}
              receiver={suggestedChanges.receiver as 'beneficiary' | 'depositor'}
              items={[
                {
                  label: 'Beneficiary Deposit Display Information',
                  value: suggestedChangesPayload['show_deposit_information'] ? 'Show' : "Don't Show",
                },
                ...(!escrow.showDepositInformation && suggestedChangesPayload?.['beneficiary_deposit_notification']
                  ? [
                      {
                        label: 'Deposit Notification type to',
                        value: escrowNotificationsDictionary(EscrowPartyTypeEnum.Beneficiary)[
                          suggestedChangesPayload['beneficiary_deposit_notification']
                        ].heading,
                      },
                    ]
                  : []),
                ...(!escrow.showDepositInformation && disruptedSuggestedChangesItems.beneficiary.length
                  ? disruptedSuggestedChangesItems.beneficiary
                  : []),
              ]}
            />
          )}

          <div className={`${styles['selected-option']} ${escrow.showDepositInformation ? styles['mb--40'] : ''}`}>
            <i>
              <CheckCircleFilled />
            </i>
            <strong>{escrow.showDepositInformation ? 'Show' : "Don't Show"}</strong>
          </div>

          {escrow.showDepositInformation && (
            <Fragment>
              <div className={`${styles['block-types']} ${styles['mb--40']}`}>
                <TextBlock
                  className={styles['mb--20']}
                  title="Deposit Notification Type"
                  titleColor="dark-blue"
                  text="The confirmation sent to the beneficiary when deposits are made."
                  as="h4"
                />

                {suggestedChanges && suggestedChangesPayload?.['beneficiary_deposit_notification'] && (
                  <SuggestChangesList
                    className={styles['container--990']}
                    status={suggestedChanges.status as 'accepted' | 'pending'}
                    currentCompanyRole={escrow.currentCompanyRole as 'beneficiary' | 'depositor'}
                    creator={suggestedChanges.creator as 'beneficiary' | 'depositor'}
                    receiver={suggestedChanges.receiver as 'beneficiary' | 'depositor'}
                    items={[
                      {
                        label: 'Deposit Notification type to',
                        value: escrowNotificationsDictionary(EscrowPartyTypeEnum.Beneficiary)[
                          suggestedChangesPayload['beneficiary_deposit_notification']
                        ].heading,
                      },
                    ]}
                  />
                )}

                {beneficiaryNotificationType && (
                  <CardWithIcon className={styles['selected-card']} {...beneficiaryNotificationType} />
                )}
              </div>

              <TextBlock
                className={styles['mb--20']}
                title="Beneficiary Disrupted Connection Notifications"
                text="Notifications to the beneficiary if the connection to an automatically synced asset is disrupted, and syncing cannot take place."
              />

              {/* Beneficiary suggested changes */}
              {suggestedChanges &&
                Boolean(disruptedSuggestedChangesItems.beneficiary.length) &&
                ('beneficiary_disruption_notification' in suggestedChanges.payload ||
                  'beneficiary_disruption_notification_grace_period' in suggestedChanges.payload) && (
                  <SuggestChangesList
                    className={styles['container--990']}
                    status={suggestedChanges.status as 'accepted' | 'pending'}
                    currentCompanyRole={escrow.currentCompanyRole as 'beneficiary' | 'depositor'}
                    creator={suggestedChanges.creator as 'beneficiary' | 'depositor'}
                    receiver={suggestedChanges.receiver as 'beneficiary' | 'depositor'}
                    items={disruptedSuggestedChangesItems.beneficiary}
                  />
                )}
              <div className={styles['selected-option']}>
                <i>
                  <CheckCircleFilled />
                </i>
                <strong>{beneficiaryDisrupted.gracePeriod || beneficiaryDisrupted.notification}</strong>
              </div>
            </Fragment>
          )}
        </div>
      ) : (
        <TextBlock
          className={`${styles.block} ${styles['bipartite-message']}`}
          title="Deposit Information"
          text="No deposit notifications will be sent to the beneficiary of a bipartite escrow."
        />
      )}

      {/* Depositor deposit notification */}
      <div className={styles.block}>
        <div className={`${styles['block-types']} ${styles['mb--40']}`}>
          <TextBlock
            className={styles['mb--20']}
            title="Depositor Deposit  Notification Type"
            text="The confirmation sent to the depositor when deposits are made."
          />

          {suggestedChanges && suggestedChangesPayload?.['depositor_deposit_notification'] && (
            <SuggestChangesList
              className={styles['container--990']}
              status={suggestedChanges.status as 'accepted' | 'pending'}
              currentCompanyRole={escrow.currentCompanyRole as 'beneficiary' | 'depositor'}
              creator={suggestedChanges.creator as 'beneficiary' | 'depositor'}
              receiver={suggestedChanges.receiver as 'beneficiary' | 'depositor'}
              items={[
                {
                  label: 'Deposit Notification type to',
                  value: escrowNotificationsDictionary(EscrowPartyTypeEnum.Depositor)[
                    suggestedChangesPayload['depositor_deposit_notification']
                  ].heading,
                },
              ]}
            />
          )}

          {depositorNotificationType && (
            <CardWithIcon className={styles['selected-card']} {...depositorNotificationType} />
          )}
        </div>
        <TextBlock
          className={styles['mb--20']}
          title="Depositor Disrupted Connection Notifications"
          text="Notifications to the depositor if the connection to an automatically synced asset is disrupted, and syncing cannot take place."
        />
        {suggestedChanges &&
          Boolean(disruptedSuggestedChangesItems.depositor.length) &&
          ('depositor_disruption_notification' in suggestedChanges.payload ||
            'depositor_disruption_notification_grace_period' in suggestedChanges.payload) && (
            <SuggestChangesList
              className={styles['container--990']}
              status={suggestedChanges.status as 'accepted' | 'pending'}
              currentCompanyRole={escrow.currentCompanyRole as 'beneficiary' | 'depositor'}
              creator={suggestedChanges.creator as 'beneficiary' | 'depositor'}
              receiver={suggestedChanges.receiver as 'beneficiary' | 'depositor'}
              items={disruptedSuggestedChangesItems.depositor}
            />
          )}
        <div className={styles['selected-option']}>
          <i>
            <CheckCircleFilled />
          </i>
          <strong>{depositorDisrupted.gracePeriod || depositorDisrupted.notification}</strong>
        </div>
      </div>

      <div className={styles.block}>
        <TextBlock
          className={styles['mb--20']}
          title="Storage Region"
          text="The selected storage region. Non-default regions are available with our Advanced Deposits Add-on."
        />

        {suggestedChanges && suggestedChangesPayload?.['storage_region'] && (
          <SuggestChangesList
            className={styles['container--990']}
            status={suggestedChanges.status as 'accepted' | 'pending'}
            currentCompanyRole={escrow.currentCompanyRole as 'beneficiary' | 'depositor'}
            creator={suggestedChanges.creator as 'beneficiary' | 'depositor'}
            receiver={suggestedChanges.receiver as 'beneficiary' | 'depositor'}
            items={[
              {
                label: 'Storage Region to',
                value: storageRegionDictionary[suggestedChangesPayload['storage_region']],
              },
            ]}
          />
        )}
        <div className={styles['selected-option']}>
          <i>
            <CheckCircleFilled />
          </i>
          <strong>{activeRegionData}</strong>
        </div>
      </div>

      <ModalConfirmation
        isOpen={ui.isConfirmModalOpen}
        title="Your suggestions have been sent for review!"
        text="Codekeeper will now review your change suggestions. We will notify you of the result as soon as possible."
        onClose={() => setConfirmModalOpen(false)}
      />
    </Fragment>
  );
};

export default ViewNotificationsChangesContainer;

function getDisruptedConnectionDetails(
  disruptionNotification: string,
  disruptionNotificationGracePeriod: string | null,
) {
  return {
    notification: disruptedNotificationsDictionary[disruptionNotification],
    gracePeriod:
      disruptionNotification === DisruptionNotificationEnum.ImmediateNotification
        ? gracePeriodDictionary[disruptionNotificationGracePeriod ?? '']
        : undefined,
  };
}
