import { LoginContainer } from 'src/containers/Authentication';
import { usePageMeta } from 'src/hooks';

const LoginPage = () => {
  usePageMeta('Login');

  return <LoginContainer />;
};

export default LoginPage;
