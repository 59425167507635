import { ConfirmationMessageBlock } from 'src/components/Escrow/Party';
import { TextBlock } from 'src/components/Misc';
import { useEscrow } from 'src/context/escrow';
import { EscrowPartyTypeEnum, AgreementFormEnum } from 'src/graphql/schema';

type Props = {
  partyType: EscrowPartyTypeEnum;
  suggestedChangesNode: React.ReactNode;
};

const ActivationNotificationView = ({ partyType, suggestedChangesNode }: Props) => {
  const {
    escrow: { agreement, activationNotification },
  } = useEscrow();

  const isBipartiteBeneficiary =
    partyType === EscrowPartyTypeEnum.Beneficiary && agreement?.agreementForm === AgreementFormEnum.Bipartite;

  if (!isBipartiteBeneficiary) return null;

  return (
    <div className="mt-5">
      <TextBlock
        title="Beneficiary Confirmation Message"
        text="This setting enables or disables notifications sent to the beneficiary contact person when this escrow is activated."
        className="mb-5"
      />
      {suggestedChangesNode}
      <ConfirmationMessageBlock value={activationNotification} />
    </div>
  );
};

export default ActivationNotificationView;
