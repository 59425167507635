import { ForgotPasswordContainer } from 'src/containers/Authentication';
import { usePageMeta } from 'src/hooks';

const ForgotPasswordPage = () => {
  usePageMeta('Forgot Password');

  return <ForgotPasswordContainer />;
};

export default ForgotPasswordPage;
