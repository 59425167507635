import { useCallback, useEffect } from 'react';
import { difference, intersection } from 'lodash';

import { useAgreementSuggestChangesSlice } from 'src/slices';
import { useEscrow } from 'src/context/escrow';
import { useSuggestedChanges } from 'src/hooks/use-client-suggested-changes';
import {
  SuggestedChangeChangeTypeEnum,
  useCreateSuggestedChangeEscrowMutation,
  useClientChangesRequestedQuery,
  IAgreementChangeInput,
  IClientChangesRequestedQuery,
  ISuggestedChangesQuery,
  SuggestedChangeStatusEnum,
  EscrowPartyTypeEnum,
} from 'src/graphql/schema';
import { writeNewSuggestChanges, suggestChangesVar } from 'src/graphql/client/cache';

import { createRequiredContext } from '../createRequiredContext';

const setAgreementDataPayload = (newValues: string[], oldValues: string[]) => {
  const intersectionData = intersection(newValues, oldValues) || [];
  const differenceData = difference(newValues, oldValues) || [];
  let newData: string[] = [...intersectionData, ...differenceData];

  if (newData.length === oldValues.length) {
    newData = newData.every((item) => oldValues.includes(item)) ? [] : newData;
  }

  return newData;
};

const [useAgreementSuggestChanges, AgreementSuggestChangesProvider] = createRequiredContext<
  Omit<ReturnType<typeof useAgreementSuggestChangesSlice>, 'setInitialData'> & {
    isLoading: boolean;
    suggestedChanges: IClientChangesRequestedQuery['clientChangesRequested']['agreement'];
    sendSuggestedChanges: () => Promise<boolean>;
  }
>();

const AgreementContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { escrow } = useEscrow();
  const onCompletedSuggestedChanges = useCallback((suggestedChanges: ISuggestedChangesQuery['suggestedChanges']) => {
    const availableChanges = suggestedChanges.nodes;

    if (availableChanges.length) {
      writeNewSuggestChanges(suggestChangesVar)('agreement', {
        id: availableChanges[0].id,
        creator: availableChanges[0].creatorType,
        status: availableChanges[0].partyStatus as SuggestedChangeStatusEnum,
        payload: availableChanges[0].payload,
        receiver: availableChanges[0].receiverType as EscrowPartyTypeEnum,
      });
    }
  }, []);
  const { loading } = useSuggestedChanges({
    escrowId: escrow.id,
    changeType: SuggestedChangeChangeTypeEnum.AgreementSuggestedChange,
    onCompleted: onCompletedSuggestedChanges,
  });
  const { state, setInitialData, ...actions } = useAgreementSuggestChangesSlice();
  useEffect(() => {
    const initialSettings = {
      context: escrow.agreement?.legalContext || [],
      otherContext: escrow.agreement?.legalContextOther || '',
      deposit: escrow.agreement?.depositType || [],
      otherDeposit: escrow.agreement?.depositTypeOther || '',
      condition: escrow.agreement?.releaseCustomConditions || '',
      jurisdiction: escrow?.agreement?.jurisdiction || '',
      liability: escrow.agreement?.liability || '',
      releaseConditions: {
        releaseBankruptcy: Boolean(escrow.agreement?.releaseBankruptcy),
        releaseCustom: Boolean(escrow.agreement?.releaseCustom),
        releaseInsolvency: Boolean(escrow.agreement?.releaseInsolvency),
        releaseMaintenance: Boolean(escrow.agreement?.releaseMaintenance),
      },
    };
    setInitialData(initialSettings);
  }, []);

  const { data: changesRequestedData } = useClientChangesRequestedQuery();
  const [createSuggestedChanges] = useCreateSuggestedChangeEscrowMutation();

  const sendSuggestedChanges = useCallback(async () => {
    const changes = {
      context: setAgreementDataPayload(state.data.context, escrow.agreement?.legalContext || []),
      otherContext:
        state.data.otherContext !== escrow.agreement?.legalContextOther ? state.data.otherContext : undefined,
      deposit: setAgreementDataPayload(state.data.deposit, escrow.agreement?.depositType || []),
      otherDeposit:
        state.data.otherDeposit !== escrow.agreement?.depositTypeOther ? state.data.otherDeposit : undefined,
      releaseBankruptcy:
        state.data.releaseConditions.releaseBankruptcy !== escrow.agreement?.releaseBankruptcy
          ? state.data.releaseConditions.releaseBankruptcy
          : undefined,
      releaseInsolvency:
        state.data.releaseConditions.releaseInsolvency !== escrow.agreement?.releaseInsolvency
          ? state.data.releaseConditions.releaseInsolvency
          : undefined,
      releaseMaintenance:
        state.data.releaseConditions.releaseMaintenance !== escrow.agreement?.releaseMaintenance
          ? state.data.releaseConditions.releaseMaintenance
          : undefined,
      releaseCustom:
        state.data.releaseConditions.releaseCustom !== escrow.agreement?.releaseCustom
          ? state.data.releaseConditions.releaseCustom
          : undefined,
      condition:
        state.data.releaseConditions.releaseCustom && escrow.agreement?.releaseCustomConditions !== state.data.condition
          ? state.data.condition
          : undefined,
      jurisdiction: escrow.agreement?.jurisdiction !== state.data.jurisdiction && state.data.jurisdiction,
      liability: escrow.agreement?.liability !== state.data.liability && state.data.liability,
    };

    try {
      const { data } = await createSuggestedChanges({
        variables: {
          escrowId: escrow.id,
          suggestedChangeParams: {
            agreementSuggestedChange: {
              ...(changes.context.length && { legalContext: changes.context }),
              ...(changes.otherContext && { legalContextOther: changes.otherContext }),
              ...(changes.deposit.length && { depositType: changes.deposit }),
              ...(changes.otherDeposit && { depositTypeOther: changes.otherDeposit }),
              ...(typeof changes.releaseBankruptcy === 'boolean' && {
                releaseBankruptcy: changes.releaseBankruptcy,
              }),
              ...(typeof changes.releaseInsolvency === 'boolean' && {
                releaseInsolvency: changes.releaseInsolvency,
              }),
              ...(typeof changes.releaseMaintenance === 'boolean' && {
                releaseMaintenance: changes.releaseMaintenance,
              }),
              ...(typeof changes.releaseCustom === 'boolean' && {
                releaseCustom: changes.releaseCustom,
              }),
              ...(changes.condition && { releaseCustomConditions: changes.condition }),
              ...(changes.jurisdiction && {
                jurisdiction: changes.jurisdiction,
              }),
              ...(changes.liability && { liability: changes.liability }),
            } as IAgreementChangeInput,
          },
        },
      });
      if (data?.createSuggestedChangeEscrow?.errors?.length) {
        throw Error(data?.createSuggestedChangeEscrow?.errors[0]);
      } else {
        writeNewSuggestChanges(suggestChangesVar)('agreement', {
          id: data?.createSuggestedChangeEscrow?.suggestedChange?.id,
          creator: data?.createSuggestedChangeEscrow?.suggestedChange?.creatorType,
          status: data?.createSuggestedChangeEscrow?.suggestedChange?.partyStatus as SuggestedChangeStatusEnum,
          payload: data?.createSuggestedChangeEscrow?.suggestedChange?.payload,
          receiver: data?.createSuggestedChangeEscrow?.suggestedChange?.receiverType as EscrowPartyTypeEnum,
        });
        return true;
      }
    } catch (e: unknown) {
      throw Error(String(e));
    }
  }, [state.data]);

  const providerValue = {
    state,
    suggestedChanges: changesRequestedData?.clientChangesRequested.agreement,
    isLoading: loading,
    sendSuggestedChanges,
    ...actions,
  };

  return <AgreementSuggestChangesProvider value={providerValue}>{children}</AgreementSuggestChangesProvider>;
};

export { useAgreementSuggestChanges, AgreementContextProvider };
