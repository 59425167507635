import { useEffect } from 'react';

import { useEscrow } from 'src/context/escrow';
import { suggestChangesVar, writeResetSuggestChanges } from 'src/graphql/client/cache';
import { JourneyLayout } from 'src/components/Journey';
import { usePageMeta } from 'src/hooks';

import EscrowFooterContainer from './EscrowFooterContainer';
import EscrowAlertBannerContainer from './EscrowAlertBannerContainer';
import EscrowMenuContainer from './EscrowMenuContainer';
import EscrowStatusIndicatorContainer from './EscrowStatusIndicatorContainer';
import EscrowHeaderContainer from './EscrowHeaderContainer';
import EscrowNavigationContainer from './EscrowNavigationContainer';

const EscrowLayoutContainer = ({ children }: React.PropsWithChildren) => {
  const { escrow, error } = useEscrow();

  const escrowName = [escrow.depositor?.companyName, escrow.beneficiary?.companyName].filter(Boolean).join(' - ');
  usePageMeta(escrowName);

  // Clean up suggestChangesVar on unmount
  useEffect(() => {
    return () => {
      writeResetSuggestChanges(suggestChangesVar);
    };
  }, []);

  return (
    <JourneyLayout
      header={<EscrowHeaderContainer />}
      error={error && { goTo: '/escrows', ...error }}
      statusIndicator={<EscrowStatusIndicatorContainer />}
      menu={<EscrowMenuContainer />}
    >
      <EscrowAlertBannerContainer />
      {children}
      <EscrowFooterContainer />
      <EscrowNavigationContainer />
    </JourneyLayout>
  );
};

export default EscrowLayoutContainer;
