import React from 'react';

import { JourneyStatusIndicator, IJourneyStatusIndicatorItem } from 'src/components/Journey/StatusIndicator';

import { statusIndicatorItems, EscrowStatusIndicatorEnum } from './statusIndicator.enum';

type Props = {
  currentStatus: EscrowStatusIndicatorEnum;
};

const EscrowStatusIndicator = ({ currentStatus }: Props) => {
  const isCanceled = [
    EscrowStatusIndicatorEnum.TERMINATED_REQUESTED,
    EscrowStatusIndicatorEnum.CANCELLATION_INITIATED,
    EscrowStatusIndicatorEnum.CANCELED,
  ].includes(currentStatus);

  const newStatusIndicatorItems: IJourneyStatusIndicatorItem[] = (() => {
    if (currentStatus === EscrowStatusIndicatorEnum.CANCELED)
      return statusIndicatorItems.map((item) => ({
        ...item,
        status: item.id === currentStatus ? 'canceled' : 'completed',
        isHidden: item.id === currentStatus ? false : item.isHidden,
      }));

    if (
      currentStatus === EscrowStatusIndicatorEnum.TERMINATED_REQUESTED ||
      currentStatus === EscrowStatusIndicatorEnum.CANCELLATION_INITIATED
    )
      return statusIndicatorItems.map((item) => ({
        ...item,
        status: item.id === currentStatus ? 'pending-cancellation' : 'completed',
        isHidden: item.id === currentStatus ? false : item.isHidden,
      }));

    if (currentStatus === EscrowStatusIndicatorEnum.ACTIVE)
      return statusIndicatorItems.map((item) => ({
        ...item,
        status: 'completed',
      }));

    const currentStatusIndex = statusIndicatorItems.findIndex(({ id }) => id === currentStatus);

    return statusIndicatorItems.map((item, index) =>
      index <= currentStatusIndex ? { ...item, status: item.id === currentStatus ? 'active' : 'completed' } : item,
    );
  })();

  return (
    <JourneyStatusIndicator isCanceled={isCanceled} skeletonItems={4}>
      {newStatusIndicatorItems.map((item) => (
        <JourneyStatusIndicator.Item key={item.id} {...item} />
      ))}
    </JourneyStatusIndicator>
  );
};

export default React.memo(EscrowStatusIndicator);
