import { LazyLoad } from 'src/components/Misc';

import type { RouteObject } from 'react-router-dom';

const EscrowRolePreviewContainer = () => (
  <LazyLoad loader={() => import('src/containers/Escrow/Role/EscrowRolePreviewContainer')} />
);
const EscrowNotificationsContainer = () => (
  <LazyLoad loader={() => import('src/containers/Escrow/Notifications/EscrowNotificationsContainer')} />
);
const EscrowAgreementContainer = () => (
  <LazyLoad loader={() => import('src/containers/Escrow/Agreement/EscrowAgreementContainer')} />
);
const EscrowGeneratedAgreementContainer = () => (
  <LazyLoad loader={() => import('src/containers/Escrow/Agreement/EscrowGeneratedAgreementContainer')} />
);
const EscrowDepositsContainer = () => (
  <LazyLoad loader={() => import('src/containers/Escrow/Deposits/EscrowDepositsContainer')} />
);
const EscrowCertificateContainer = () => (
  <LazyLoad loader={() => import('src/containers/Escrow/Certificate/EscrowCertificateContainer')} />
);
const EscrowReportsContainer = () => (
  <LazyLoad loader={() => import('src/containers/Escrow/Reports/EscrowReportsContainer')} />
);
const EscrowReportContainer = () => (
  <LazyLoad loader={() => import('src/containers/Escrow/Report/EscrowReportContainer')} />
);
const EscrowReportEmptyContainer = () => (
  <LazyLoad loader={() => import('src/containers/Escrow/Report/ReportEmptyContainer')} />
);
const EscrowDepositorContainer = () => (
  <LazyLoad loader={() => import('src/containers/Escrow/Depositor/EscrowDepositorContainer')} />
);
const EscrowBeneficiaryContainer = () => (
  <LazyLoad loader={() => import('src/containers/Escrow/Beneficiary/EscrowBeneficiaryContainer')} />
);
const EscrowMembersContainer = () => (
  <LazyLoad loader={() => import('src/containers/Escrow/Members/EscrowMembersContainer')} />
);

export enum EscrowRoutesEnum {
  Basics = 'basics',
  Members = 'members',
  Notifications = 'notifications',
  Agreement = 'agreement',
  Preview = 'preview',
  Deposits = 'deposits',
  Certificate = 'certificate',
  Reports = 'reports',
  Depositor = 'depositor',
  Beneficiary = 'beneficiary',
}

export const escrowRouteElements: RouteObject[] = [
  {
    path: EscrowRoutesEnum.Basics,
    element: <EscrowRolePreviewContainer />,
  },
  {
    path: EscrowRoutesEnum.Depositor,
    element: <EscrowDepositorContainer />,
  },
  {
    path: EscrowRoutesEnum.Beneficiary,
    element: <EscrowBeneficiaryContainer />,
  },
  {
    path: EscrowRoutesEnum.Members,
    element: <EscrowMembersContainer />,
  },
  {
    path: EscrowRoutesEnum.Notifications,
    element: <EscrowNotificationsContainer />,
  },
  {
    path: EscrowRoutesEnum.Agreement,
    element: <EscrowAgreementContainer />,
  },
  {
    path: EscrowRoutesEnum.Preview,
    element: <EscrowGeneratedAgreementContainer />,
  },
  {
    path: EscrowRoutesEnum.Deposits,
    element: <EscrowDepositsContainer />,
  },
  {
    path: EscrowRoutesEnum.Certificate,
    element: <EscrowCertificateContainer />,
  },
  {
    path: EscrowRoutesEnum.Reports,
    element: <EscrowReportsContainer />,
  },
  {
    path: `${EscrowRoutesEnum.Reports}/:reportId`,
    element: <EscrowReportContainer />,
  },
  {
    path: `${EscrowRoutesEnum.Reports}/empty`,
    element: <EscrowReportEmptyContainer />,
  },
];
