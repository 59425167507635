import { Fragment } from 'react';
import { Modal } from 'antd';

import { AddRepresentative, EditRepresentative, RemoveRepresentativeModal } from './';

import { useRepresentative } from '../model';

const actionComponentMap = {
  add: AddRepresentative,
  edit: EditRepresentative,
};

const RepresentativeModalActions = () => {
  const { action, setInitialData, role } = useRepresentative();

  const ActionComponent =
    action && action in actionComponentMap ? actionComponentMap[action as keyof typeof actionComponentMap] : null;

  return (
    <Fragment>
      <Modal
        open={action === 'add' || action === 'edit'}
        footer={null}
        width={550}
        className="ck-representative__modal"
        onCancel={setInitialData}
        destroyOnClose
      >
        {ActionComponent && <ActionComponent role={role} />}
      </Modal>

      <RemoveRepresentativeModal />
    </Fragment>
  );
};

export default RepresentativeModalActions;
