import { Suspense } from 'react';

import { useEscrow } from 'src/context/escrow';
import { useClientChangesRequestedQuery } from 'src/graphql/schema';
import { JourneyModalLayout } from 'src/components/Journey/ModalLayout';
import { NotificationsContextProvider } from 'src/context/suggest-changes';
import Loader from 'src/components/Loader/Loader';

import NotificationsSuggestChangesContainer from './SuggestChanges/NotificationsSuggestChangesContainer';
import NotificationsSuggestChangesActions from './SuggestChanges/NotificationsSuggestChangesActions';
import EscrowNotificationsViewContainer from './EscrowNotificationsViewContainer';
import EscrowNotificationsEditContainer from './EscrowNotificationsEditContainer';

const EscrowModalMain = JourneyModalLayout.Main;

const EscrowNotificationsContainer = () => {
  const {
    escrow: { policy },
  } = useEscrow();
  const { data: changesRequested } = useClientChangesRequestedQuery();

  const enableSuggestChanges =
    policy.createNotificationSuggestedChange || changesRequested?.clientChangesRequested.notifications;

  // Suggest Changes Mode
  if (enableSuggestChanges) {
    return (
      <Suspense fallback={<Loader />}>
        <NotificationsContextProvider>
          <EscrowModalMain>
            <NotificationsSuggestChangesContainer />
          </EscrowModalMain>
          <NotificationsSuggestChangesActions />
        </NotificationsContextProvider>
      </Suspense>
    );
  }

  // New/Edit Mode
  if (policy.update) {
    return (
      <EscrowModalMain>
        <EscrowNotificationsEditContainer />
      </EscrowModalMain>
    );
  }

  // Preview Mode
  return (
    <EscrowModalMain>
      <EscrowNotificationsViewContainer />
    </EscrowModalMain>
  );
};

export default EscrowNotificationsContainer;
