import { useState, useEffect } from 'react';
import { Button, Form } from 'antd';

import type { FormInstance, ButtonProps } from 'antd';

type Props = {
  form: FormInstance;
  buttonProps?: ButtonProps;
};

const SubmitButton = ({ form, children, buttonProps }: React.PropsWithChildren<Props>) => {
  const [submittable, setSubmittable] = useState(false);

  const values = Form.useWatch([], form);

  useEffect(() => {
    const onValidate = async () => {
      try {
        await form.validateFields({ validateOnly: true });
        setSubmittable(true);
      } catch (error) {
        const errorInfo = error as { errorFields: { name: string; errors: string[] }[] };
        if (errorInfo.errorFields.length) setSubmittable(false);
      }
    };

    onValidate();
  }, [form, values]);

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable} {...buttonProps}>
      {children}
    </Button>
  );
};

export default SubmitButton;
