import { useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';

import {
  EscrowPartyTypeEnum,
  useCreateSuggestedChangeEscrowMutation,
  ContactTypeEnum,
  AgreementFormEnum,
} from 'src/graphql/schema';
import { usePartySuggestChangesSlice } from 'src/slices';
import { suggestChangesVar, writeNewSuggestChanges } from 'src/graphql/client/cache';

import { createRequiredContext } from '../createRequiredContext';
import { useEscrow } from '../escrow';

import type { FetchResult } from '@apollo/client';
import type {
  IContactCreateInput,
  SuggestedChangeStatusEnum,
  ICreateSuggestedChangeEscrowMutation,
} from 'src/graphql/schema';
import type { IRepresentativeForm } from 'src/components/Escrow/Representative';

const [usePartySuggestChanges, PartySuggestChangesProvider] = createRequiredContext<
  {
    isSendSuggestionsEnabled: boolean;
    partyType: EscrowPartyTypeEnum;
    agreementType: string;
    undoRepresentative: (id: string) => void;
    createSuggestedChanges: () => Promise<FetchResult<ICreateSuggestedChangeEscrowMutation>>;
    editRepresentative: (id: string, representative: Partial<IRepresentativeForm>) => void;
    canSuggestActivationNotification: boolean;
  } & Omit<ReturnType<typeof usePartySuggestChangesSlice>, 'setCurrentData' | 'undoRepresentative'>
>();

type Props = {
  partyType: EscrowPartyTypeEnum;
};

const PartySuggestChangesContextProvider = ({ partyType, children }: React.PropsWithChildren<Props>) => {
  const {
    escrow,
    escrow: { id: escrowId, agreement, activationNotification },
  } = useEscrow();
  const {
    undoRepresentative,
    editRepresentative,
    organization: suggestedOrganization,
    representatives: suggestedRepresentatives,
    activationNotification: suggestedActivationNotification,
    setCurrentData,
    ...rest
  } = usePartySuggestChangesSlice();

  const partyContacts = escrow[partyType]?.contacts ?? [];
  const representatives =
    partyContacts.map(({ id, name, email, phone, signatory }) => ({
      id,
      name,
      email,
      phone: phone || '',
      signatory,
    })) ?? [];

  useEffect(() => setCurrentData({ representatives, activationNotification }), []);

  const onUndoRepresentative = useCallback(
    (id: string) => {
      const representative = partyContacts.find((rep) => rep.id === id);

      if (!representative) return;

      undoRepresentative({
        id,
        name: representative.name,
        email: representative.email,
        phone: representative.phone ?? '',
        signatory: representative.signatory,
      });
    },
    [partyContacts, undoRepresentative],
  );

  const onEditRepresentative = useCallback(
    (id: string, representative: Partial<IRepresentativeForm>) => {
      const currentRepresentative = representatives.find((rep) => rep.id === id);

      if (!currentRepresentative) return editRepresentative(id, representative);

      // Compare old and new values to detect changes
      const changedValues = filterNullValues({
        name: getSuggestedChangeValue(representative.name, currentRepresentative.name),
        email: getSuggestedChangeValue(representative.email, currentRepresentative.email),
        phone: getSuggestedChangeValue(representative.phone, currentRepresentative.phone),
        signatory: getSuggestedChangeValue(Boolean(representative.signatory), Boolean(currentRepresentative.signatory)),
      });

      // Only mark as edit if values actually changed
      const suggestType = isEmpty(changedValues) ? undefined : 'edit';
      editRepresentative(id, representative, suggestType);
    },
    [representatives, editRepresentative],
  );

  const [createSuggestedChange] = useCreateSuggestedChangeEscrowMutation({
    onCompleted: (data) => {
      if (!data.createSuggestedChangeEscrow?.success) return;

      const suggestedChange = data.createSuggestedChangeEscrow.suggestedChange;

      if (!suggestedChange) return;

      writeNewSuggestChanges(suggestChangesVar)(partyType, {
        id: suggestedChange.id,
        creator: suggestedChange.creatorType,
        status: suggestedChange.partyStatus as SuggestedChangeStatusEnum,
        payload: suggestedChange.payload,
        receiver: suggestedChange.receiverType as EscrowPartyTypeEnum,
      });
    },
  });

  const isBipartiteBeneficiary =
    agreement?.agreementForm === AgreementFormEnum.Bipartite && partyType === EscrowPartyTypeEnum.Beneficiary;

  const organizationModifiedFields = filterNullValues({
    companyName: getSuggestedChangeValue(suggestedOrganization?.name, escrow[partyType]?.companyName ?? ''),
    companyRegistrationNumber: getSuggestedChangeValue(
      suggestedOrganization?.registrationNumber,
      escrow[partyType]?.companyRegistrationNumber ?? '',
    ),
    companyWebsite: getSuggestedChangeValue(suggestedOrganization?.website, escrow[partyType]?.companyWebsite ?? ''),
    country: getSuggestedChangeValue(suggestedOrganization?.country, escrow[partyType]?.country ?? ''),
    region: getSuggestedChangeValue(suggestedOrganization?.state, escrow[partyType]?.region ?? ''),
    city: getSuggestedChangeValue(suggestedOrganization?.city, escrow[partyType]?.city ?? ''),
    postalCode: getSuggestedChangeValue(suggestedOrganization?.zip, escrow[partyType]?.postalCode ?? ''),
    street: getSuggestedChangeValue(suggestedOrganization?.street, escrow[partyType]?.street ?? ''),
    streetNumber: getSuggestedChangeValue(suggestedOrganization?.streetNumber, escrow[partyType]?.streetNumber ?? ''),
  });

  const activationNotificationModifiedFields = filterNullValues({
    activationNotification: getSuggestedChangeValue(Boolean(suggestedActivationNotification), activationNotification),
  });

  const newContacts: IContactCreateInput[] = suggestedRepresentatives
    .filter((rep) => rep.id.includes('new-'))
    .map(({ id, suggestType, ...rest }) => ({
      ...rest,
      contactType: ContactTypeEnum.Administrative,
    }));

  const editedContacts = suggestedRepresentatives
    .filter(({ suggestType }) => suggestType === 'edit')
    .map(({ suggestType, ...rest }) => rest);

  const deletedContacts = suggestedRepresentatives
    .filter(({ suggestType }) => suggestType === 'remove')
    .map(({ id }) => ({ id }));

  const createSuggestedChanges = () => {
    const suggestedData = {
      ...organizationModifiedFields,
      ...(editedContacts.length && { editedContacts }),
      ...(newContacts.length && { newContacts }),
      ...(deletedContacts.length && { deletedContacts }),
      ...(isBipartiteBeneficiary && activationNotificationModifiedFields),
    };
    const partyParamKey = `${partyType}SuggestedChange`;

    return createSuggestedChange({
      variables: {
        escrowId,
        suggestedChangeParams: {
          [partyParamKey]: suggestedData,
        },
      },
    });
  };

  const activeRepresentatives = suggestedRepresentatives.filter(
    (representative) => representative.suggestType !== 'remove',
  );

  const isSignatoryMissing =
    activeRepresentatives.length > 0 && !activeRepresentatives.some(({ signatory }) => signatory);

  const isSendSuggestionsEnabled =
    [
      !isEmpty(organizationModifiedFields),
      !isEmpty(activationNotificationModifiedFields),
      editedContacts.length,
      newContacts.length,
      deletedContacts.length,
    ].some(Boolean) && !(isSignatoryMissing && !isBipartiteBeneficiary);

  const value = {
    ...rest,
    isSendSuggestionsEnabled,
    organization: suggestedOrganization,
    representatives: suggestedRepresentatives,
    partyType,
    agreementType: agreement?.agreementForm || '',
    undoRepresentative: onUndoRepresentative,
    editRepresentative: onEditRepresentative,
    createSuggestedChanges,
    canSuggestActivationNotification: isBipartiteBeneficiary,
    activationNotification: suggestedActivationNotification,
  };

  return <PartySuggestChangesProvider value={value}>{children}</PartySuggestChangesProvider>;
};

export { usePartySuggestChanges, PartySuggestChangesContextProvider };

function getSuggestedChangeValue(
  suggestedChangeValue: string | boolean | undefined,
  currentValue: string | boolean | undefined,
): string | boolean | null {
  if (currentValue && suggestedChangeValue === '') return '';

  if (suggestedChangeValue !== currentValue) return suggestedChangeValue ?? null;

  return null;
}

function filterNullValues(obj: Record<string, unknown>) {
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== null));
}
