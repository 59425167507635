import { memo } from 'react';
import { Button } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { JourneyFooter } from 'src/components/Journey';
import { useClientChangesRequestedQuery, EscrowStatusEnum } from 'src/graphql/schema';
import { EscrowRoutesEnum } from 'src/pages/Escrow';
import { useEscrow } from 'src/context/escrow';

import {
  GenerateAgreementActionContainer,
  ApproveProceedActionContainer,
  ActivateEscrowActionContainer,
  RequestTerminationActionContainer,
  ApproveTerminationActionContainer,
  RejectTerminationActionContainer,
} from './Actions';

const actionItemsKeys = [
  'request-changes',
  'approve-proceed',
  'generate-agreement',
  'activate-escrow',
  'close',
  'approve-termination',
  'reject-termination',
  'close-member-access',
] as const;

const EscrowFooterContainer = () => {
  const {
    escrow,
    escrow: { policy, escrowTermination },
    ui: { isSuggestChangesMode, members },
    setSuggestChangesMode,
  } = useEscrow();
  const { '*': activePath } = useParams<{
    '*': EscrowRoutesEnum;
  }>();
  const navigate = useNavigate();
  const { data: dataChangesRequested } = useClientChangesRequestedQuery();

  let actionItems: { key: (typeof actionItemsKeys)[number]; component: React.ReactNode }[] = [
    {
      key: 'close',
      component: (
        <Button
          type="default"
          onClick={() => {
            navigate('/escrows');
          }}
        >
          Close
        </Button>
      ),
    },
  ];

  // Hide for Request Changes mode
  if (isSuggestChangesMode) return null;

  // Enable Request Changes action
  const routeConditions = {
    [EscrowRoutesEnum.Depositor]: policy.createDepositorSuggestedChange,
    [EscrowRoutesEnum.Beneficiary]: policy.createBeneficiarySuggestedChange,
    [EscrowRoutesEnum.Agreement]: policy.createAgreementSuggestedChange,
    [EscrowRoutesEnum.Notifications]: policy.createNotificationSuggestedChange,
    [EscrowRoutesEnum.Deposits]: policy.createDepositSuggestedChange,
  };
  const requestChangesConditions: Array<(path?: EscrowRoutesEnum) => boolean> = [
    () => !isSuggestChangesMode,
    // @ts-expect-error dynamic type
    (activePath) => !dataChangesRequested?.clientChangesRequested[activePath],
    // @ts-expect-error dynamic type
    (activePath) => activePath && activePath in routeConditions && routeConditions[activePath],
  ];
  if (requestChangesConditions.every((condition) => condition(activePath))) {
    actionItems.unshift({
      key: 'request-changes',
      component: (
        <Button
          type="text"
          onClick={() => setSuggestChangesMode(true)}
          style={{
            color: '#EB8D00',
            borderColor: '#EB8D00',
          }}
          title="Request Changes"
        >
          Request Changes
        </Button>
      ),
    });
  }

  // Disable Close action, if there are changes requested for the receiver on the current path
  if (
    // @ts-expect-error dynamic type
    dataChangesRequested?.clientChangesRequested[activePath] &&
    // @ts-expect-error dynamic type
    dataChangesRequested?.clientChangesRequested[activePath].status === 'pending' &&
    // @ts-expect-error dynamic type
    dataChangesRequested.clientChangesRequested[activePath].receiver === escrow.currentCompanyRole
  ) {
    actionItems = actionItems.filter(({ key }) => key !== 'close');
  }

  // Enable Generate Agreement action
  if (escrow.status === EscrowStatusEnum.Draft)
    actionItems = [
      ...actionItems.filter(({ key }) => key !== 'close'),
      {
        key: 'generate-agreement',
        component: <GenerateAgreementActionContainer />,
      },
    ];

  // Enable Approve & Proceed action
  if (
    !dataChangesRequested?.clientChangesRequested.isRequested &&
    escrow.status === EscrowStatusEnum.Onboarding &&
    !escrow.escrowTermination
  )
    actionItems = [
      ...actionItems.filter(({ key }) => key !== 'close'),
      {
        key: 'approve-proceed',
        component: (
          <ApproveProceedActionContainer escrowId={escrow.id} canApprove={escrow.agreement?.policy.approve ?? false} />
        ),
      },
    ];

  // Enable Activate escrow action
  if (!dataChangesRequested?.clientChangesRequested.isRequested && escrow.status === EscrowStatusEnum.Signed)
    actionItems = [
      ...actionItems.filter(({ key }) => key !== 'close'),
      {
        key: 'activate-escrow',
        component: <ActivateEscrowActionContainer />,
      },
    ];

  if (members.isEditAccessMode) {
    const closeMemberAccess = () => members.setEditAccessMode(false);

    actionItems = [
      {
        key: 'close-member-access',
        component: (
          <Button type="default" onClick={closeMemberAccess}>
            Close
          </Button>
        ),
      },
    ];
  }

  // Enable Approve Termination action
  if (escrowTermination?.policy.accept && escrowTermination.policy.reject)
    actionItems = [
      ...actionItems.filter(({ key }) => key !== 'close'),
      {
        key: 'reject-termination',
        component: <RejectTerminationActionContainer />,
      },
      {
        key: 'approve-termination',
        component: <ApproveTerminationActionContainer />,
      },
    ];

  if (!actionItems.length) return null;

  return (
    <JourneyFooter justify={policy.requestTermination ? 'space-between' : 'end'}>
      {policy.requestTermination && (
        <JourneyFooter.Item>
          <RequestTerminationActionContainer />
        </JourneyFooter.Item>
      )}

      <JourneyFooter.Group>
        {actionItems.map(({ key, component }) => (
          <JourneyFooter.Item key={key}>{component}</JourneyFooter.Item>
        ))}
      </JourneyFooter.Group>
    </JourneyFooter>
  );
};

export default memo(EscrowFooterContainer);
