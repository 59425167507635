import { ModalProceed } from 'src/components/Misc';

type Props = {
  isOpen: boolean;
  type: 'accept' | 'reject';
  onCancel: () => void;
  onProceed: () => void;
  isLoading?: boolean;
};

const SuggestChangesActionModal = ({ isOpen, onCancel, type, onProceed, isLoading }: Props) => {
  const actionContent = {
    accept: {
      title: 'Are you sure you want to accept these suggestions?',
      text: "These suggestions will be sent for review by Codekeeper, and will affect your escrow's configuration.",
      btnText: 'Accept Suggestions',
    },
    reject: {
      title: 'Are you sure you want to reject these suggestions?',
      text: "Your escrow's configuration will not be updated.",
      btnText: 'Reject Suggestions',
    },
  };

  return (
    <ModalProceed
      isOpen={isOpen}
      onCancel={onCancel}
      title={actionContent[type].title}
      text={actionContent[type].text}
      proceedText={actionContent[type].btnText}
      isLoading={isLoading}
      onProceed={onProceed}
    />
  );
};

export default SuggestChangesActionModal;
