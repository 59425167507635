import { useState } from 'react';

import { ModalProceed } from 'src/components/Misc';

import { useRepresentative } from '../model';

const RemoveRepresentativeModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { action, representativeId, setInitialData, submit } = useRepresentative();

  const onProceed = async () => {
    setIsLoading(true);

    try {
      await submit?.remove(representativeId ?? '');
      setInitialData();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalProceed
      isOpen={action === 'remove'}
      title="Are you sure you want to remove this representative?"
      text="This person will no longer be listed as a point of contact in the escrow agreement."
      onCancel={setInitialData}
      onProceed={onProceed}
      proceedText="Remove"
      proceedButtonProps={{
        danger: true,
        ghost: true,
      }}
      isLoading={isLoading}
    />
  );
};

export default RemoveRepresentativeModal;
