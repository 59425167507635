import { Suspense } from 'react';

import { useEscrow } from 'src/context/escrow';
import { useClientChangesRequestedQuery } from 'src/graphql/schema';
import { JourneyModalLayout } from 'src/components/Journey/ModalLayout';
import { AgreementContextProvider } from 'src/context/suggest-changes';
import Loader from 'src/components/Loader/Loader';

import AgreementSuggestChangesContainer from './SuggestChanges/AgreementSuggestChangesContainer';
import AgreementSuggestChangesActions from './SuggestChanges/AgreementSuggestChangesActions';
import EscrowAgreementViewContainer from './EscrowAgreementViewContainer';
import EscrowAgreementEditContainer from './EscrowAgreementEditContainer';

const EscrowModalMain = JourneyModalLayout.Main;

const EscrowAgreementContainer = () => {
  const {
    escrow: { policy },
  } = useEscrow();
  const { data: changesRequested } = useClientChangesRequestedQuery();

  const enableSuggestChanges =
    policy.createAgreementSuggestedChange || changesRequested?.clientChangesRequested.agreement;

  // Suggest Changes Mode
  if (enableSuggestChanges) {
    return (
      <Suspense fallback={<Loader />}>
        <AgreementContextProvider>
          <EscrowModalMain>
            <AgreementSuggestChangesContainer />
          </EscrowModalMain>
          <AgreementSuggestChangesActions />
        </AgreementContextProvider>
      </Suspense>
    );
  }

  // New/Edit Mode
  if (policy.update) {
    return (
      <EscrowModalMain>
        <EscrowAgreementEditContainer />
      </EscrowModalMain>
    );
  }

  // Preview Mode
  return (
    <EscrowModalMain>
      <EscrowAgreementViewContainer />
    </EscrowModalMain>
  );
};

export default EscrowAgreementContainer;
