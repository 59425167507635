import { useEffect } from 'react';

type IMetaTag = {
  name: string;
  content: string;
};

export const usePageMeta = (title: string, metas: IMetaTag[] | null = null): void => {
  useEffect(() => {
    const originalTitle = 'Codekeeper';
    document.title = title + ' | ' + originalTitle;

    const createdMetaTags: HTMLMetaElement[] = [];

    if (metas) {
      metas.forEach((meta) => {
        let metaTag = document.querySelector<HTMLMetaElement>(`meta[name="${meta.name}"]`);

        if (!metaTag) {
          metaTag = document.createElement('meta');
          metaTag.name = meta.name;
          document.head.appendChild(metaTag);
          createdMetaTags.push(metaTag);
        }

        metaTag.content = meta.content;
      });
    }

    return () => {
      document.title = originalTitle;

      createdMetaTags.forEach((metaTag) => {
        document.head.removeChild(metaTag);
      });
    };
  }, [title, metas]);
};
