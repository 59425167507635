import { Switch, Tooltip } from 'antd';
import { useState, Fragment } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';

import { useEscrow } from 'src/context/escrow';

import { useRepresentative } from '../model';

import type { IRepresentative } from '../model';
import type { TableColumnsType } from 'antd';

const SignatoryAction = ({ id: representativeId, ...representative }: IRepresentative) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    ui: { isSuggestChangesMode },
  } = useEscrow();
  const { submit, currentPolicyRole } = useRepresentative();

  const onChange = async (signatory: boolean) => {
    setIsLoading(true);

    try {
      await submit?.edit(representativeId, { ...representative, signatory });
    } finally {
      setIsLoading(false);
    }
  };

  if (currentPolicyRole?.update || isSuggestChangesMode)
    return (
      <Switch
        onChange={onChange}
        value={Boolean(representative.signatory)}
        loading={isLoading}
        disabled={representative.suggestType === 'remove'}
      />
    );

  return <Switch value={Boolean(representative.signatory)} disabled />;
};

const SignatoryActionColumn = (isSignatoryMissing: boolean): TableColumnsType<IRepresentative> => [
  {
    title: () => (
      <Fragment>
        <span>Signatory </span>

        {isSignatoryMissing && (
          <Tooltip
            placement="bottom"
            title="Assign at least one (1) representative below who will be responsible for signing the agreement."
          >
            <ExclamationCircleFilled
              style={{
                fontSize: 12,
                lineHeight: 12,
                color: '#FDAF19',
              }}
            />
          </Tooltip>
        )}
      </Fragment>
    ),
    dataIndex: 'signatory',
    render: (_, representative: IRepresentative) => <SignatoryAction {...representative} />,
  },
];

export { SignatoryActionColumn, SignatoryAction };
