import { CheckCircleFilled } from '@ant-design/icons';

import { TextBlock } from 'src/components/Misc';
import { CardWithIconList } from 'src/components/Card';
import {
  escrowLegalContextDictionary,
  escrowDepositTypeDictionary,
  escrowReleaseDictionary,
  escrowJurisdictionDictionary,
  escrowLiabilityDictionary,
} from 'src/components/Escrow/Agreement';
import { useEscrow } from 'src/context/escrow';
import styles from 'src/components/Escrow/Escrow.module.scss';

const EscrowAgreementViewContainer = () => {
  const {
    escrow: { agreement },
  } = useEscrow();

  const legalContentItems =
    agreement?.legalContext.map((item) => escrowLegalContextDictionary[item]).filter(Boolean) || [];
  const depositItems = agreement?.depositType.map((item) => escrowDepositTypeDictionary[item]).filter(Boolean) || [];
  const releaseConditions: { [key: string]: boolean } = {
    releaseBankruptcy: agreement?.releaseBankruptcy || false,
    releaseInsolvency: agreement?.releaseInsolvency || false,
    releaseMaintenance: agreement?.releaseMaintenance || false,
    releaseCustom: agreement?.releaseCustom || false,
  };
  const releaseConditionsItems = Object.keys(releaseConditions)
    .filter((key) => Boolean(releaseConditions[key]))
    .map((key) => escrowReleaseDictionary[key]);
  const jurisdictionOption = (agreement?.jurisdiction && escrowJurisdictionDictionary[agreement?.jurisdiction]) || '';
  const liabilityOption = (agreement?.liability && escrowLiabilityDictionary[agreement.liability]) || '';

  if (agreement?.thirdPartyAgreement) {
    return (
      <div className={styles.block}>
        <TextBlock
          className={styles['mb--30']}
          title="Escrow Agreement Details"
          text="You opted to make use of an external, third party escrow agreement. The details of the agreement are available in the Preview section."
        />
        <TextBlock
          className={styles['mb--20']}
          title="External Third Party Escrow Agreement"
          titleColor="dark-blue"
          as="h4"
          text=""
        />
        <div className={styles['selected-option']}>
          <i>
            <CheckCircleFilled />
          </i>
          <strong>Use external third party escrow agreement</strong>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.block}>
      <TextBlock
        className={styles['mb--30']}
        title="Escrow Agreement Details"
        text="These settings will configure the escrow agreement, which you can review and edit in the next steps."
      />

      <div className={`${styles['bb--dashed']} ${styles['mb--40']}`}>
        <TextBlock
          className={styles['mb--20']}
          title="Legal Context"
          titleColor="dark-blue"
          as="h4"
          text="The legal context of the agreement that discusses this escrow arrangement."
        />
        <div className={styles['container--990']}>
          <CardWithIconList
            className={`${styles['card-list__row']} ${styles['mb--40']}`}
            col={5}
            items={legalContentItems}
          />
          {agreement?.legalContextOther && (
            <div className={`${styles['other-text__block']} ${styles['mb--40']}`}>
              <strong>Other agreement type:</strong>
              <p>{agreement.legalContextOther}</p>
            </div>
          )}
        </div>
      </div>

      <div className={`${styles['bb--dashed']} ${styles['mb--40']}`}>
        <TextBlock
          className={styles['mb--20']}
          title="Deposit Type"
          titleColor="dark-blue"
          as="h4"
          text="The materials that will be deposited into escrow."
        />
        <div className={styles['container--990']}>
          <CardWithIconList
            className={`${styles['card-list__row']} ${styles['mb--40']}`}
            col={5}
            items={depositItems}
          />
          {agreement?.depositTypeOther && (
            <div className={`${styles['other-text__block']} ${styles['mb--40']}`}>
              <strong>Other deposit type:</strong>
              <p>{agreement.depositTypeOther}</p>
            </div>
          )}
        </div>
      </div>

      <div className={`${styles['bb--dashed']} ${styles['mb--40']}`}>
        <TextBlock
          className={styles['mb--20']}
          title="Release Conditions"
          titleColor="dark-blue"
          as="h4"
          text="The selected conditions under which the release should take place."
        />
        <div className={styles['container--990']}>
          <CardWithIconList
            className={`${styles['card-list__row']} ${styles['mb--40']}`}
            col={5}
            items={releaseConditionsItems}
          />
          {agreement?.releaseCustomConditions && (
            <div className={`${styles['other-text__block']} ${styles['mb--40']}`}>
              <strong>Custom Conditions:</strong>
              <p>{agreement?.releaseCustomConditions}</p>
            </div>
          )}
        </div>
      </div>

      <div className={`${styles['bb--dashed']} ${styles['mb--40']}`}>
        <TextBlock
          className={styles['mb--20']}
          title="Jurisdiction"
          titleColor="dark-blue"
          as="h4"
          text="The legal jurisdiction that will be applicable to the escrow agreement."
        />
        <div className={`${styles['selected-option']} ${styles['mb--40']}`}>
          <i>
            <CheckCircleFilled />
          </i>
          <strong>{jurisdictionOption}</strong>
        </div>
      </div>

      <div className={`${styles['bb--dashed']} ${styles['mb--40']}`}>
        <TextBlock
          className={styles['mb--20']}
          title="Escrow Agreement Liability SLA"
          titleColor="dark-blue"
          as="h4"
          text="The selected liability and SLA applied to this escrow agreement."
        />
        <div className={`${styles['selected-option']} ${styles['mb--40']}`}>
          <i>
            <CheckCircleFilled />
          </i>
          <strong>{liabilityOption}</strong>
        </div>
      </div>
    </div>
  );
};

export default EscrowAgreementViewContainer;
