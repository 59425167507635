import { Fragment, useCallback } from 'react';

import { message, TextBlock } from 'src/components/Misc';
import {
  LegalContextSelect,
  DepositTypeSelect,
  ReleaseConditionsSelect,
  EscrowJurisdictionSelect,
  EscrowLiabilitySelect,
  ExternalThirdPartySelect,
} from 'src/components/Escrow/Agreement';
import { useUpdateAgreementMutation } from 'src/graphql/schema';
import { useEscrow } from 'src/context/escrow';
import styles from 'src/components/Escrow/Escrow.module.scss';
import { getApiError, ClientError } from 'src/utils/errors';

const EscrowAgreementEditContainer = () => {
  const {
    escrow: { id: escrowId, agreement },
  } = useEscrow();

  const [updateAgreement] = useUpdateAgreementMutation();

  const onUpdateAgreement = useCallback(async (agreementInput: Record<string, unknown>) => {
    try {
      const { data } = await updateAgreement({
        variables: {
          escrowId,
          agreementInput,
        },
      });

      if (data?.updateAgreement?.success) {
        message.success('Saved!');
      } else {
        throw new ClientError(data?.updateAgreement?.errors?.[0]);
      }
    } catch (error: unknown) {
      getApiError(error, message.error);
    }
  }, []);

  return (
    <div className={styles.block}>
      <TextBlock
        className={styles['mb--30']}
        title="Escrow Agreement Details"
        text="These settings will configure the escrow agreement, which you can review and edit in the next steps. "
      />
      <ExternalThirdPartySelect isExternal={agreement?.thirdPartyAgreement} onChange={onUpdateAgreement} />

      {!agreement?.thirdPartyAgreement && (
        <Fragment>
          <LegalContextSelect
            selectedValues={agreement?.legalContext || []}
            legalContextOther={agreement?.legalContextOther || ''}
            onChange={(legalContext) => onUpdateAgreement({ legalContext })}
            onOtherChange={(legalContextOther) => onUpdateAgreement({ legalContextOther })}
          />
          <DepositTypeSelect
            selectedValues={agreement?.depositType || []}
            depositTypeOther={agreement?.depositTypeOther || ''}
            onChange={(depositType) => onUpdateAgreement({ depositType })}
            onOtherChange={(depositTypeOther) => onUpdateAgreement({ depositTypeOther })}
          />
          <ReleaseConditionsSelect
            releaseConditions={{
              releaseBankruptcy: agreement?.releaseBankruptcy || false,
              releaseInsolvency: agreement?.releaseInsolvency || false,
              releaseMaintenance: agreement?.releaseMaintenance || false,
              releaseCustom: agreement?.releaseCustom || false,
            }}
            releaseCustomConditions={agreement?.releaseCustomConditions as string | undefined}
            onChangeConditions={onUpdateAgreement}
            onChangeCustomCondition={onUpdateAgreement}
          />
          <EscrowJurisdictionSelect
            selectedValue={agreement?.jurisdiction as string | null}
            onChange={(jurisdiction) => onUpdateAgreement({ jurisdiction })}
          />
          <EscrowLiabilitySelect
            selectedValue={agreement?.liability as string | null}
            onChange={(liability) => onUpdateAgreement({ liability })}
          />
        </Fragment>
      )}
    </div>
  );
};

export default EscrowAgreementEditContainer;
