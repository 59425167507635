import { useSuspenseQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { useEffect } from 'react';

import {
  EscrowStatusEnum,
  SuggestedChangeStatusEnum,
  EscrowPartyTypeEnum,
  SuggestedChangesNotificationsDocument,
} from 'src/graphql/schema';
import { suggestChangesVar, writeInitialSuggestChangesData } from 'src/graphql/client/cache';
import { useEscrow } from 'src/context/escrow';

import type { ISuggestedChangesNotificationsQuery } from 'src/graphql/schema';

/* eslint-disable react/display-name */
export const withSuggestedChangesApi = (Component: React.ElementType) => () => {
  const {
    escrow: { id: escrowId, status },
  } = useEscrow();
  const { data } = useSuspenseQuery<ISuggestedChangesNotificationsQuery>(SuggestedChangesNotificationsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      escrowId,
    },
    skip:
      !escrowId ||
      [
        EscrowStatusEnum.Draft,
        EscrowStatusEnum.AdminReview,
        EscrowStatusEnum.PendingTermination,
        EscrowStatusEnum.Terminated,
      ].includes(status),
  });

  useEffect(() => {
    const onCompleted = ({
      agreementSuggestedChanges,
      depositorSuggestedChanges,
      beneficiarySuggestedChanges,
      notificationSuggestedChanges,
      depositSuggestedChanges,
    }: ISuggestedChangesNotificationsQuery) => {
      // Check if any suggestions are requested
      const isRequested =
        Boolean(depositorSuggestedChanges.nodesCount) ||
        Boolean(beneficiarySuggestedChanges.nodesCount) ||
        Boolean(depositSuggestedChanges.nodesCount) ||
        Boolean(notificationSuggestedChanges.nodesCount) ||
        Boolean(agreementSuggestedChanges.nodesCount);

      if (!isRequested) return;

      let allChanges = cloneDeep(suggestChangesVar()) as ReturnType<typeof suggestChangesVar>;

      // A helper to apply changes for a given key if there are nodes
      const applyChange = (
        key: keyof typeof allChanges,
        changesObj: {
          nodesCount: number;
          nodes: ISuggestedChangesNotificationsQuery['agreementSuggestedChanges']['nodes'];
        },
      ) => {
        if (changesObj.nodes.length) {
          const node = changesObj.nodes[0];

          if (node.partyStatus === SuggestedChangeStatusEnum.Rejected) return;

          allChanges = {
            ...allChanges,
            [key]: {
              id: node.id,
              status: node.partyStatus as SuggestedChangeStatusEnum,
              creator: node.creatorType,
              receiver: node.receiverType as EscrowPartyTypeEnum,
              // @ts-expect-error dynamic type
              payload: allChanges[key]?.payload ?? null,
            },
          };
        }
      };

      applyChange('depositor', depositorSuggestedChanges);
      applyChange('beneficiary', beneficiarySuggestedChanges);
      applyChange('deposits', depositSuggestedChanges);
      applyChange('notifications', notificationSuggestedChanges);
      applyChange('agreement', agreementSuggestedChanges);

      writeInitialSuggestChangesData(suggestChangesVar)(allChanges);
    };

    if (data) onCompleted(data);
  }, [data]);

  return <Component />;
};
