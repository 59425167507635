import { Fragment, useState } from 'react';
import { Button } from 'antd';

import { RepresentativeForm } from 'src/components/Escrow/Representative';
import { useEscrow } from 'src/context/escrow';
import { AgreementFormEnum, EscrowPartyTypeEnum } from 'src/graphql/schema';

import { useRepresentative } from '../model';

import type { IRepresentativeForm } from 'src/components/Escrow/Representative';

type Props = {
  role: EscrowPartyTypeEnum;
};

const EditRepresentative = ({ role }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { representatives, removeRepresentative, representativeId, setInitialData, submit, changesRequested } =
    useRepresentative();
  const {
    escrow: { agreement },
  } = useEscrow();

  const representativeData = representatives.find((rep) => rep.id === representativeId);

  if (!representativeData) return null;

  const onRemove = () => removeRepresentative(representativeId ?? '');

  const onSubmit = async (values: IRepresentativeForm) => {
    setIsLoading(true);

    try {
      await submit?.edit(representativeId ?? '', values);
      setInitialData();
    } finally {
      setIsLoading(false);
    }
  };

  const initialValues = {
    name: representativeData?.name ?? '',
    email: representativeData?.email ?? '',
    phone: representativeData?.phone ?? '',
    signatory: representativeData?.signatory ?? false,
  };
  const showSignatory =
    agreement?.agreementForm === AgreementFormEnum.Tripartite ||
    (agreement?.agreementForm === AgreementFormEnum.Bipartite && role === EscrowPartyTypeEnum.Depositor);
  const activeSignatories = representatives.filter(
    ({ signatory }) => signatory && representativeData.suggestType !== 'add',
  );
  const isLastActiveSignatory =
    activeSignatories.length === 1 &&
    activeSignatories.some(({ id }) => representativeData.id === id) &&
    changesRequested;

  return (
    <Fragment>
      <h2 className="text-xl text-pacific-blue mb-5">Edit Representative</h2>

      <RepresentativeForm
        submitText="Save Changes"
        onSubmit={onSubmit}
        removeButton={
          <Button danger ghost={!isLastActiveSignatory} onClick={onRemove} disabled={isLastActiveSignatory}>
            Remove Representative
          </Button>
        }
        initialValues={initialValues}
        withSignatory={showSignatory}
        isLoading={isLoading}
      />
    </Fragment>
  );
};

export default EditRepresentative;
