import { memo } from 'react';
import classNames from 'classnames';

import type { SVGProps } from 'react';
const SvgIconDepositor = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  const svgClasses = classNames('ck-icon', className);
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      role="img"
      className={svgClasses}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.415 11.58c-.58.387-.815.835-.815 1.22a.8.8 0 0 1-1.6 0c0-1.087.66-1.973 1.528-2.551.872-.582 2.032-.915 3.272-.915s2.4.333 3.272.915c.867.578 1.528 1.464 1.528 2.551a.8.8 0 0 1-1.6 0c0-.386-.235-.833-.815-1.22-.576-.383-1.416-.646-2.385-.646s-1.81.263-2.385.646M5.799 3.6a1.867 1.867 0 1 0 0 3.733 1.867 1.867 0 0 0 0-3.733M2.332 5.467a3.467 3.467 0 1 1 6.933 0 3.467 3.467 0 0 1-6.933 0M15.367 8.034a.8.8 0 0 1-.8.8h-4a.8.8 0 0 1 0-1.6h4a.8.8 0 0 1 .8.8"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.257 10.7a.8.8 0 0 1-.222-1.11l1.037-1.557-1.037-1.556a.8.8 0 0 1 1.331-.887l1.334 2a.8.8 0 0 1 0 .887l-1.334 2a.8.8 0 0 1-1.11.222"
        clipRule="evenodd"
      />
    </svg>
  );
};
const Memo = memo(SvgIconDepositor);
export default Memo;
