import { JourneyLayout } from 'src/components/Journey';
import { useBackup } from 'src/context/backup';
import { usePageMeta } from 'src/hooks';

import BackupHeaderContainer from './BackupHeaderContainer';
import BackupStatusIndicatorContainer from './BackupStatusIndicatorContainer';
import BackupMenuContainer from './BackupMenuContainer';
import BackupAlertBannerContainer from './BackupAlertBannerContainer';
import BackupFooterContainer from './BackupFooterContainer';
import BackupNavigationContainer from './BackupNavigationContainer';

const BackupLayoutContainer = ({ children }: React.PropsWithChildren) => {
  const {
    backup: { integration },
    error,
  } = useBackup();
  usePageMeta(integration.accountName || '');

  return (
    <JourneyLayout
      header={<BackupHeaderContainer />}
      error={error && { goTo: '/backups', ...error }}
      statusIndicator={<BackupStatusIndicatorContainer />}
      menu={<BackupMenuContainer />}
    >
      <BackupAlertBannerContainer />
      {children}
      <BackupFooterContainer />
      <BackupNavigationContainer />
    </JourneyLayout>
  );
};

export default BackupLayoutContainer;
