import { useReducer } from 'react';

import { IAction } from 'src/utils/ts-utilities';

import type { IOrganizationForm } from 'src/components/Escrow/Party';
import type { IRepresentative } from 'src/containers/Escrow/Representatives/model';
import type { IRepresentativeForm } from 'src/components/Escrow/Representative';

enum ActionEnum {
  SET_ORGANIZATION = 'set_organization',
  SET_INITIAL = 'set_initial',
  SET_CURRENT_DATA = 'set_current_data',
  EDIT_REPRESENTATIVE = 'edit_representative',
  REMOVE_REPRESENTATIVE = 'remove_representative',
  ADD_REPRESENTATIVE = 'add_representative',
  UNDO_REPRESENTATIVE = 'undo_representative',
  SET_ACTIVATION_NOTIFICATION = 'set_activation_notification',
}

type IActionReducer =
  | IAction<ActionEnum.SET_ORGANIZATION, Partial<IOrganizationForm>>
  | IAction<ActionEnum.SET_INITIAL>
  | IAction<ActionEnum.EDIT_REPRESENTATIVE, Partial<IRepresentative> & { id: string; suggestType?: 'edit' }>
  | IAction<ActionEnum.REMOVE_REPRESENTATIVE, { id: string }>
  | IAction<ActionEnum.ADD_REPRESENTATIVE, IRepresentativeForm>
  | IAction<ActionEnum.UNDO_REPRESENTATIVE, IRepresentative>
  | IAction<ActionEnum.SET_ACTIVATION_NOTIFICATION, boolean>
  | IAction<
      ActionEnum.SET_CURRENT_DATA,
      {
        representatives: IRepresentative[];
        activationNotification?: boolean;
      }
    >;
type IState = {
  organization: Partial<IOrganizationForm> | null;
  representatives: IRepresentative[];
  activationNotification: boolean | null;
};

const initialState: IState = {
  organization: null,
  representatives: [],
  activationNotification: null,
};

const reducer = (state: IState, action: IActionReducer): IState => {
  switch (action.type) {
    case ActionEnum.SET_ORGANIZATION:
      return { ...state, organization: { ...state.organization, ...action.payload } };
    case ActionEnum.SET_INITIAL:
      return initialState;
    case ActionEnum.EDIT_REPRESENTATIVE: {
      const { id } = action.payload;
      const suggestType = id.startsWith('new-') ? 'add' : action.payload.suggestType;

      return {
        ...state,
        representatives: state.representatives.map((rep) =>
          rep.id === action.payload.id ? { ...rep, ...action.payload, suggestType } : rep,
        ),
      };
    }
    case ActionEnum.REMOVE_REPRESENTATIVE: {
      const { id } = action.payload;

      if (id.startsWith('new-'))
        return {
          ...state,
          representatives: state.representatives.filter((rep) => rep.id !== id),
        };

      return {
        ...state,
        representatives: state.representatives.map((rep) =>
          rep.id === action.payload.id ? { ...rep, suggestType: 'remove' } : rep,
        ),
      };
    }
    case ActionEnum.ADD_REPRESENTATIVE:
      return {
        ...state,
        representatives: [
          ...state.representatives,
          {
            ...action.payload,
            id: 'new-' + Date.now(),
            suggestType: 'add',
            signatory: action.payload.signatory || false,
          },
        ],
      };
    case ActionEnum.UNDO_REPRESENTATIVE: {
      const { id } = action.payload;
      const index = state.representatives.findIndex((rep) => rep.id === id);

      if (index === -1) return state;

      const updated = [...state.representatives];
      updated[index] = action.payload;

      return {
        ...state,
        representatives: updated,
      };
    }
    case ActionEnum.SET_ACTIVATION_NOTIFICATION:
      return { ...state, activationNotification: action.payload };
    case ActionEnum.SET_CURRENT_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const usePartySuggestChangesSlice = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setOrganization = (organization: Partial<IOrganizationForm>) => {
    dispatch({ type: ActionEnum.SET_ORGANIZATION, payload: organization });
  };

  const setInitial = () => {
    dispatch({ type: ActionEnum.SET_INITIAL, payload: {} });
  };

  const editRepresentative = (id: string, representative: Partial<IRepresentativeForm>, suggestType?: 'edit') =>
    dispatch({ type: ActionEnum.EDIT_REPRESENTATIVE, payload: { ...representative, id, suggestType } });

  const removeRepresentative = (id: string) => dispatch({ type: ActionEnum.REMOVE_REPRESENTATIVE, payload: { id } });

  const addRepresentative = (representative: IRepresentativeForm) =>
    dispatch({ type: ActionEnum.ADD_REPRESENTATIVE, payload: representative });

  const undoRepresentative = (representative: IRepresentative) =>
    dispatch({ type: ActionEnum.UNDO_REPRESENTATIVE, payload: representative });

  const setActivationNotification = (activationNotification: boolean) =>
    dispatch({ type: ActionEnum.SET_ACTIVATION_NOTIFICATION, payload: activationNotification });

  const setCurrentData = (data: { representatives: IRepresentative[]; activationNotification?: boolean }) =>
    dispatch({ type: ActionEnum.SET_CURRENT_DATA, payload: data });

  return {
    ...state,
    setOrganization,
    setInitial,
    editRepresentative,
    removeRepresentative,
    addRepresentative,
    undoRepresentative,
    setActivationNotification,
    setCurrentData,
  };
};
