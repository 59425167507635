export const setOrganizationFullAddress = (address: {
  street: string;
  city: string;
  postalCode: string;
  region: string;
  country: string;
  streetNumber?: string;
}) =>
  Object.entries(address)
    .filter(([, value]) => Boolean(value))
    .reduce((acc, [key, value], index) => {
      if (index === 0) return `${acc}`;
      if (index === 1 && key === 'streetNumber') return `${acc} ${value}`;
      else return `${acc}, ${value}`;
    }, address.street);
