import { DepositStatusEnum } from 'src/graphql/schema';
import { formatBytes } from 'src/utils/formatBytes';

import type { IAssetNodes } from 'src/containers/Escrow';
import type { ITableRow as IAssetsTableRow } from 'src/components/Deposit';

const depositStatusMap = {
  [DepositStatusEnum.NoConfiguration]: 'configuring',
};

export const getDepositsAssets = (assetsNodes: IAssetNodes): IAssetsTableRow[] => {
  if (!assetsNodes.length) return [];

  return assetsNodes.map((item) => ({
    key: item.id,
    type: item.type,
    provider: item.integration?.provider || '',
    source: item.depositSource,
    latestDeposit: item.latestDeposit,
    lastUpdateCheck: {
      lastUpdateCheck: item.lastUpdateCheck,
      type: item.type,
    },
    status:
      item.depositStatus in depositStatusMap
        ? depositStatusMap[item.depositStatus as keyof typeof depositStatusMap]
        : item.depositStatus,
    size: item.size ? formatBytes(item.size) : '-',
    action: {
      id: item.id,
      type: item.removalStatus,
    },
  })) as IAssetsTableRow[];
};
