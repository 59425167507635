import classNames from 'classnames';
import { capitalize, isEmpty } from 'lodash';

import SuggestChangeItem, { ISuggestChangesItem } from './SuggestChangeItem';
import styles from './SuggestChangesList.module.scss';

type Props = {
  status: 'accepted' | 'pending';
  items: ISuggestChangesItem[];
  currentCompanyRole: 'beneficiary' | 'depositor';
  creator: 'beneficiary' | 'depositor';
  receiver: 'beneficiary' | 'depositor';
  className?: string;
};

const SuggestChangesList: React.FunctionComponent<Props> = ({
  status,
  items,
  currentCompanyRole,
  creator,
  receiver,
  className,
}) => {
  const changesClassNames = classNames(styles.container, styles[status], className);
  let labelText = `${capitalize(currentCompanyRole === creator ? 'your' : `${creator}'s`)} change suggestions:`;

  if (status === 'accepted')
    labelText = `${
      currentCompanyRole === receiver ? 'You have accepted' : `${capitalize(receiver)} has accepted`
    } these change requests below:`;

  if (isEmpty(items)) return null;

  return (
    <div className={changesClassNames}>
      <i>{labelText}</i>
      <ul className={styles.list}>
        {items.map((item) => (
          <SuggestChangeItem key={item.value} {...item} />
        ))}
      </ul>
    </div>
  );
};

export default SuggestChangesList;
