import { gql } from '@apollo/client';
import { PartialDeep } from 'type-fest';

import { EscrowPartyTypeEnum } from 'src/graphql/schema';
import { EscrowInvitationFields } from 'src/graphql/actions/escrow/escrow.fragment';

import { apolloClient as client } from '../apollo-client';

import type { IDepositor, IBeneficiary, IContact, IEscrowInvitation } from 'src/graphql/schema';

const DepositorContactFragment = gql`
  fragment DepositorContactFragment on Escrow {
    depositor {
      contacts {
        id
        contactType
        email
        name
        phone
        user {
          email
          id
          name
          phone
          role
        }
      }
    }
  }
`;
const BeneficiaryContactFragment = gql`
  fragment BeneficiaryContactFragment on Escrow {
    beneficiary {
      contacts {
        id
        contactType
        email
        name
        phone
        user {
          email
          id
          name
          phone
          role
        }
      }
    }
  }
`;

const DepositorFragment = gql`
  fragment DepositorFragment on Escrow {
    depositor {
      city
      companyName
      companyRegistrationNumber
      companyWebsite
      country
      id
      postalCode
      region
      street
      streetNumber
    }
  }
`;

const BeneficiaryFragment = gql`
  fragment BeneficiaryFragment on Escrow {
    beneficiary {
      city
      companyName
      companyRegistrationNumber
      companyWebsite
      country
      id
      postalCode
      region
      street
      streetNumber
    }
  }
`;

export const updateDepositorCache = (escrowId: string, depositor?: PartialDeep<IDepositor>) => {
  if (!depositor) return;

  client.writeFragment({
    id: `Escrow:${escrowId}`,
    fragment: DepositorFragment,
    data: {
      depositor,
    },
  });
};

export const updateBeneficiaryCache = (escrowId: string, beneficiary?: PartialDeep<IBeneficiary>) => {
  if (!beneficiary) return;

  client.writeFragment({
    id: `Escrow:${escrowId}`,
    fragment: BeneficiaryFragment,
    data: {
      beneficiary,
    },
  });
};

export const updateContactsCache = (
  escrowId: string,
  role: EscrowPartyTypeEnum,
  organization?: IBeneficiary | IDepositor | null,
  contacts?: IContact[],
) => {
  if (!organization || !contacts) return;

  client.writeFragment({
    id: `Escrow:${escrowId}`,
    fragment: role === EscrowPartyTypeEnum.Depositor ? DepositorContactFragment : BeneficiaryContactFragment,
    data: {
      [role]: {
        ...organization,
        contacts,
      },
    },
  });
};

export const addEscrowInvitationCache = (escrowId: string, invitation: IEscrowInvitation) =>
  client.writeFragment({
    id: `Escrow:${escrowId}`,
    fragment: gql`
      fragment AddEscrowInvitation on Escrow {
        escrowInvitation {
          ...EscrowInvitationFields
        }
      }
      ${EscrowInvitationFields}
    `,
    fragmentName: 'AddEscrowInvitation',
    data: {
      escrowInvitation: invitation,
    },
  });
