import { cloneDeep } from 'lodash';

import { escrowMenuItemsDictionary } from 'src/components/Escrow/Menu/escrowMenu.enum';
import { EscrowRoutesEnum } from 'src/pages/Escrow';

import type { IEscrowMenuItem } from 'src/components/Escrow/Menu';
import type { IClientChangesRequestedQuery, IClientChangesRequested } from 'src/graphql/schema';

export const applySuggestedChangesToMenuItems = (
  menuItems: IEscrowMenuItem[],
  changesRequestedData: IClientChangesRequestedQuery,
) => {
  const activeSuggestedChanges = changesRequestedData.clientChangesRequested as unknown as Record<
    string,
    IClientChangesRequested
  >;

  return menuItems.map((item) => {
    const statusChanges = item.path in activeSuggestedChanges ? activeSuggestedChanges[item.path] : '';

    return statusChanges
      ? {
          ...item,
          statusChanges: statusChanges.status as IEscrowMenuItem['statusChanges'],
        }
      : { ...item };
  });
};

export const updateMenuItemsForDraftOrAdminReview = ({
  depositorConfigured,
  beneficiaryConfigured,
  agreementSettingsConfigured,
}: {
  depositorConfigured: boolean;
  beneficiaryConfigured: boolean;
  agreementSettingsConfigured: boolean;
}) => {
  const menuItemsDict = cloneDeep(escrowMenuItemsDictionary);

  const updateMenuItem = (key: keyof typeof menuItemsDict, isRequired = false, isDisabled = false) =>
    (menuItemsDict[key] = {
      ...menuItemsDict[key],
      isRequired,
      isDisabled,
    });

  updateMenuItem(EscrowRoutesEnum.Basics, false);
  updateMenuItem(EscrowRoutesEnum.Members, false, false);
  updateMenuItem(EscrowRoutesEnum.Beneficiary, !beneficiaryConfigured, false);
  updateMenuItem(EscrowRoutesEnum.Depositor, !depositorConfigured, false);
  updateMenuItem(EscrowRoutesEnum.Notifications, false, false);
  updateMenuItem(EscrowRoutesEnum.Agreement, !agreementSettingsConfigured, false);

  return Object.values(menuItemsDict);
};
