import { EditFilled } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { Fragment } from 'react';
import { useSuspenseQuery } from '@apollo/client';
import dayjs from 'dayjs';

import { AlertActionMessage } from 'src/components/Alert';
import { OtherEscrowStatusEnum, alertMessagesTypeEnum } from 'src/components/Escrow/escrow.enum';
import {
  useClientChangesRequestedQuery,
  LegacyReposCountByEscrowIdDocument,
  EscrowStatusEnum,
  EscrowPartyTypeEnum,
  LegacyRepoMigrationStatusEnum,
} from 'src/graphql/schema';
import { useEscrow } from 'src/context/escrow';
import { MigrationAlert } from 'src/components/Migration';

import type { ILegacyReposCountByEscrowIdQuery } from 'src/graphql/schema';

const actionPaths = ['depositor', 'beneficiary', 'deposits', 'notifications', 'agreement'] as const;

const EscrowAlertBannerContainer = () => {
  const {
    escrow,
    ui: { isSuggestChangesMode },
  } = useEscrow();
  const { data: changesRequestedData } = useClientChangesRequestedQuery();
  const { data: legacyReposData } = useSuspenseQuery<ILegacyReposCountByEscrowIdQuery>(
    LegacyReposCountByEscrowIdDocument,
    {
      variables: { id: escrow?.id, statusFilter: LegacyRepoMigrationStatusEnum.Awaiting },
    },
  );
  const location = useLocation();

  let escrowStatus: OtherEscrowStatusEnum | EscrowStatusEnum = escrow?.status;

  if (escrow?.pendingActivation) escrowStatus = OtherEscrowStatusEnum.ActivationProcess;

  if (escrowStatus === EscrowStatusEnum.Onboarding && escrow.agreement?.approved)
    escrowStatus = OtherEscrowStatusEnum.AwaitingSignature;

  if (escrowStatus === EscrowStatusEnum.Signed && escrow?.depositStatus === 'no_configuration')
    if (escrow.currentCompanyRole === EscrowPartyTypeEnum.Depositor) {
      escrowStatus = OtherEscrowStatusEnum.SignedNoDepositsDepositor;
    } else {
      escrowStatus = OtherEscrowStatusEnum.SignedNoDepositsBeneficiary;
    }

  if (escrowStatus === EscrowStatusEnum.Signed && escrow?.depositStatus !== 'no_configuration')
    escrowStatus = OtherEscrowStatusEnum.SignedWithDeposits;

  if (
    [EscrowStatusEnum.Onboarding, EscrowStatusEnum.Signed, EscrowStatusEnum.Active].includes(escrow?.status) &&
    escrow?.escrowTermination
  ) {
    if (escrow?.escrowTermination.requesterParty === escrow.currentCompanyRole) {
      escrowStatus = OtherEscrowStatusEnum.TerminationRequestedSuggester;
    } else {
      escrowStatus = OtherEscrowStatusEnum.TerminationRequestedReceiver;
    }
  }

  // Messages for Request Changes mode
  const activePath = location.pathname.split('/').at(-1) as (typeof actionPaths)[number];
  if (
    changesRequestedData?.clientChangesRequested.isRequested &&
    changesRequestedData?.clientChangesRequested[activePath] &&
    !escrow?.escrowTermination
  ) {
    if (changesRequestedData?.clientChangesRequested[activePath]?.creator === escrow?.currentCompanyRole) {
      escrowStatus = OtherEscrowStatusEnum.ChangesRequestedSuggester;
    } else escrowStatus = OtherEscrowStatusEnum.ChangesRequestedReceiver;
  }

  if (isSuggestChangesMode)
    return (
      <AlertActionMessage
        bgColor="orange"
        iconType="custom"
        icon={EditFilled as React.ForwardRefExoticComponent<Pick<AntdIconProps, keyof AntdIconProps>>}
        iconColor="dark-green"
      >
        You are in edit mode for this escrow configuration. Use the <strong>‘Send Suggestions’</strong> button when you
        are finished editing the escrow.
      </AlertActionMessage>
    );

  const alertMessage:
    | ({ color?: string } & (typeof alertMessagesTypeEnum)[keyof typeof alertMessagesTypeEnum])
    | undefined =
    escrowStatus && escrowStatus in alertMessagesTypeEnum
      ? alertMessagesTypeEnum[escrowStatus as keyof typeof alertMessagesTypeEnum]
      : undefined;

  if (!alertMessage) return null;

  if (escrowStatus === EscrowStatusEnum.PendingTermination) {
    const terminationDate = dayjs(escrow?.escrowTermination?.terminateAt).format('DD MMMM YYYY');

    return (
      <AlertActionMessage
        bgColor={alertMessage.bgColor}
        text={alertMessage.text.replace(/{date}/, terminationDate)}
        color={alertMessage.color}
      />
    );
  }

  return (
    <Fragment>
      <AlertActionMessage
        iconType={alertMessage.iconType}
        bgColor={alertMessage.bgColor}
        text={alertMessage.text}
        color={alertMessage.color}
      />
      {Boolean(legacyReposData.legacyRepos.count) && escrow.currentCompanyRole !== EscrowPartyTypeEnum.Beneficiary && (
        <MigrationAlert nodesCount={legacyReposData.legacyRepos.count} />
      )}
    </Fragment>
  );
};

export default EscrowAlertBannerContainer;
