import { Form, Input, Button, Select, FormInstance } from 'antd';

import { IOrganizationInfo } from 'src/components/Account/Organization/OrganizationDetailsInfo';

import type { ICountry } from 'src/shared/entities';

export type IOrganizationForm = {
  company: string;
  street: string;
  number: string;
  city: string;
  zip: string;
  state: string;
  country: string;
  website: string;
  registration: string;
};

type Props = {
  toggleEdit: () => void;
  form: FormInstance<IOrganizationForm>;
  company: IOrganizationInfo;
  onUpdate: (data: IOrganizationForm) => void;
  onFinish?: () => void;
  countries: ICountry[];
};

export default function OrganizationForm({ form, company, onUpdate, onFinish, countries, toggleEdit }: Props) {
  const countriesOptions: IOptionType[] = countries.map((country) => ({
    label: country.name,
    value: country.name,
  }));

  return (
    <Form layout="vertical" form={form} onFinish={company ? onUpdate : onFinish}>
      <Form.Item
        style={{
          display: 'inline-block',
          width: 'calc(50% - 12px)',
          marginRight: 16,
        }}
      >
        <Form.Item
          name="company"
          label="Company Name"
          rules={[{ required: true, message: 'Please add the company name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Company Website" name="website">
          <Input />
        </Form.Item>
        <Form.Item name="registration" label="Company Registration Number">
          <Input />
        </Form.Item>
      </Form.Item>

      <Form.Item
        style={{
          display: 'inline-block',
          width: 'calc(50% - 12px)',
        }}
      >
        <div className="d-flex">
          <Form.Item
            label="Address"
            name="street"
            className="w-100"
            rules={[{ required: true, message: 'Please add the street name!' }]}
          >
            <Input name="street" placeholder="Street address" />
          </Form.Item>
        </div>
        <div className="d-flex">
          <Form.Item name="number" className="w-100">
            <Input placeholder="Number/Suite/Unit/Floor" />
          </Form.Item>
        </div>
        <div className="d-flex">
          <Form.Item name="city" className="w-100 mr-3" rules={[{ required: true, message: 'Please add the city!' }]}>
            <Input placeholder="City" />
          </Form.Item>
          <Form.Item name="state" className="w-100">
            <Input placeholder="State/Province/Region" />
          </Form.Item>
        </div>
        <div className="d-flex">
          <Form.Item
            name="zip"
            className="w-100 mr-3"
            rules={[{ required: true, message: 'Please add the postal zip!' }]}
          >
            <Input placeholder="Postal Zip" className="mr-3 mb-4" />
          </Form.Item>
          <Form.Item name="country" className="w-100" rules={[{ required: true, message: 'Please add a country!' }]}>
            <Select
              placeholder="Country"
              className="invite-screen__select mb-4"
              options={countriesOptions}
              showSearch
              filterOption={(input, option) =>
                ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </div>
      </Form.Item>

      <div className="add-company-modal__footer d-flex">
        {company && (
          <Form.Item className="mb-0">
            <Button
              className="add-company-form__save ant-btn-default--primary mr-2"
              onClick={() => {
                toggleEdit();
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        )}
        <Form.Item colon={false} className="mb-0">
          <Button htmlType="submit" type="primary" className="add-company-form__save">
            Save Details
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}
