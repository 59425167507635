import { gql } from '@apollo/client';

export const ContactFragment = gql`
  fragment ContactFragment on Contact {
    id
    email
    name
    phone
    signatory
    contactType
  }
`;
